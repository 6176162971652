export const bombDefence = [
  {AP:0,MDM:1,TISF:170,TISC:130,SDM:1,CHSDM:1,FHSDM:1},
  {AP:3,MDM:0.9131,TISF:181.5884,TISC:141.5884,SDM:0.9662,CHSDM:0.9517,FHSDM:0.9614},
  {AP:6,MDM:0.8305,TISF:192.5936,TISC:152.5936,SDM:0.9341,CHSDM:0.9059,FHSDM:0.9247},
  {AP:9,MDM:0.7524,TISF:203.0156,TISC:163.0156,SDM:0.9037,CHSDM:0.8624,FHSDM:0.8899},
  {AP:10,MDM:0.7273,TISF:206.36,TISC:166.36,SDM:0.894,CHSDM:0.8485,FHSDM:0.8788},
  {AP:12,MDM:0.6786,TISF:212.8544,TISC:172.8544,SDM:0.875,CHSDM:0.8214,FHSDM:0.8572},
  {AP:13,MDM:0.655,TISF:216.0044,TISC:176.0044,SDM:0.8658,CHSDM:0.8083,FHSDM:0.8467},
  {AP:15,MDM:0.6092,TISF:222.11,TISC:182.11,SDM:0.848,CHSDM:0.7829,FHSDM:0.8263},
  {AP:16,MDM:0.587,TISF:225.0656,TISC:185.0656,SDM:0.8394,CHSDM:0.7706,FHSDM:0.8164},
  {AP:18,MDM:0.5441,TISF:230.7824,TISC:190.7824,SDM:0.8227,CHSDM:0.7467,FHSDM:0.7974},
  {AP:19,MDM:0.5234,TISF:233.5436,TISC:193.5436,SDM:0.8147,CHSDM:0.7352,FHSDM:0.7882},
  {AP:20,MDM:0.5032,TISF:236.24,TISC:196.24,SDM:0.8068,CHSDM:0.724,FHSDM:0.7792},
  {AP:21,MDM:0.4835,TISF:238.8716,TISC:198.8716,SDM:0.7991,CHSDM:0.713,FHSDM:0.7704},
  {AP:22,MDM:0.4642,TISF:241.4384,TISC:201.4384,SDM:0.7916,CHSDM:0.7023,FHSDM:0.7619},
  {AP:23,MDM:0.4454,TISF:243.9404,TISC:203.9404,SDM:0.7843,CHSDM:0.6919,FHSDM:0.7535},
  {AP:24,MDM:0.4272,TISF:246.3776,TISC:206.3776,SDM:0.7772,CHSDM:0.6818,FHSDM:0.7454},
  {AP:25,MDM:0.4094,TISF:248.75,TISC:208.75,SDM:0.7703,CHSDM:0.6719,FHSDM:0.7375},
  {AP:26,MDM:0.3921,TISF:251.0576,TISC:211.0576,SDM:0.7636,CHSDM:0.6623,FHSDM:0.7298},
  {AP:27,MDM:0.3752,TISF:253.3004,TISC:213.3004,SDM:0.757,CHSDM:0.6529,FHSDM:0.7223},
  {AP:28,MDM:0.3589,TISF:255.4784,TISC:215.4784,SDM:0.7507,CHSDM:0.6438,FHSDM:0.7151},
  {AP:29,MDM:0.3431,TISF:257.5916,TISC:217.5916,SDM:0.7445,CHSDM:0.635,FHSDM:0.708},
  {AP:30,MDM:0.3277,TISF:259.64,TISC:219.64,SDM:0.7386,CHSDM:0.6265,FHSDM:0.7012},
  {AP:31,MDM:0.3128,TISF:261.6236,TISC:221.6236,SDM:0.7328,CHSDM:0.6182,FHSDM:0.6946},
  {AP:32,MDM:0.2984,TISF:263.5424,TISC:223.5424,SDM:0.7272,CHSDM:0.6102,FHSDM:0.6882},
  {AP:33,MDM:0.2845,TISF:265.3964,TISC:225.3964,SDM:0.7218,CHSDM:0.6025,FHSDM:0.682},
  {AP:34,MDM:0.2711,TISF:267.1856,TISC:227.1856,SDM:0.7165,CHSDM:0.5951,FHSDM:0.676},
  {AP:35,MDM:0.2582,TISF:268.91,TISC:228.91,SDM:0.7115,CHSDM:0.5879,FHSDM:0.6703},
  {AP:36,MDM:0.2457,TISF:270.5696,TISC:230.5696,SDM:0.7067,CHSDM:0.581,FHSDM:0.6648},
  {AP:37,MDM:0.2338,TISF:272.1644,TISC:232.1644,SDM:0.702,CHSDM:0.5743,FHSDM:0.6595},
  {AP:38,MDM:0.2223,TISF:273.6944,TISC:233.6944,SDM:0.6976,CHSDM:0.5679,FHSDM:0.6544},
  {AP:39,MDM:0.2113,TISF:275.1596,TISC:235.1596,SDM:0.6933,CHSDM:0.5618,FHSDM:0.6495},
  {AP:41,MDM:0.1908,TISF:277.8956,TISC:237.8956,SDM:0.6853,CHSDM:0.5504,FHSDM:0.6403},
  {AP:42,MDM:0.1813,TISF:279.1664,TISC:239.1664,SDM:0.6816,CHSDM:0.5451,FHSDM:0.6361},
  {AP:44,MDM:0.1636,TISF:281.5136,TISC:241.5136,SDM:0.6748,CHSDM:0.5354,FHSDM:0.6283},
  {AP:45,MDM:0.1556,TISF:282.59,TISC:242.59,SDM:0.6716,CHSDM:0.5309,FHSDM:0.6247},
  {AP:47,MDM:0.1409,TISF:284.5484,TISC:244.5484,SDM:0.6659,CHSDM:0.5227,FHSDM:0.6182},
  {AP:48,MDM:0.1343,TISF:285.4304,TISC:245.4304,SDM:0.6633,CHSDM:0.519,FHSDM:0.6152},
  {AP:51,MDM:0.1173,TISF:287.6876,TISC:247.6876,SDM:0.6567,CHSDM:0.5096,FHSDM:0.6077},
  {AP:54,MDM:0.1048,TISF:289.3616,TISC:249.3616,SDM:0.6519,CHSDM:0.5027,FHSDM:0.6021},
  {AP:57,MDM:0.1,TISF:290,TISC:250,SDM:0.65,CHSDM:0.5,FHSDM:0.6}
];

export const recoveryInInk = [
  {AP:0, frame:180, second:3},
  {AP:3, frame:174, second:2.9},
  {AP:6, frame:169, second:2.82},
  {AP:9, frame:163, second:2.72},
  {AP:10, frame:161, second:2.68},
  {AP:12, frame:158, second:2.63},
  {AP:13, frame:156, second:2.6},
  {AP:15, frame:153, second:2.55},
  {AP:16, frame:152, second:2.53},
  {AP:18, frame:149, second:2.48},
  {AP:19, frame:147, second:2.45},
  {AP:20, frame:146, second:2.43},
  {AP:21, frame:144, second:2.4},
  {AP:22, frame:143, second:2.38},
  {AP:23, frame:142, second:2.37},
  {AP:24, frame:140, second:2.33},
  {AP:25, frame:139, second:2.32},
  {AP:26, frame:138, second:2.3},
  {AP:27, frame:137, second:2.28},
  {AP:28, frame:136, second:2.27},
  {AP:29, frame:135, second:2.25},
  {AP:30, frame:133, second:2.22},
  {AP:31, frame:132, second:2.2},
  {AP:32, frame:131, second:2.18},
  {AP:33, frame:130, second:2.17},
  {AP:34, frame:129, second:2.15},
  {AP:35, frame:129, second:2.15},
  {AP:36, frame:128, second:2.13},
  {AP:37, frame:127, second:2.12},
  {AP:38, frame:126, second:2.1},
  {AP:39, frame:125, second:2.08},
  {AP:41, frame:124, second:2.07},
  {AP:42, frame:123, second:2.05},
  {AP:44, frame:122, second:2.03},
  {AP:45, frame:121, second:2.02},
  {AP:47, frame:120, second:2},
  {AP:48, frame:120, second:2},
  {AP:51, frame:119, second:1.98},
  {AP:54, frame:118, second:1.97},
  {AP:57, frame:117, second:1.95}
];

export const recoveryStanding = [
  {AP:0, frame:600, second:10},
  {AP:3, frame:564, second:9.4},
  {AP:6, frame:529, second:8.82},
  {AP:9, frame:496, second:8.27},
  {AP:10, frame:485, second:8.08},
  {AP:12, frame:465, second:7.75},
  {AP:13, frame:455, second:7.58},
  {AP:15, frame:435, second:7.25},
  {AP:16, frame:426, second:7.1},
  {AP:18, frame:408, second:6.8},
  {AP:19, frame:399, second:6.65},
  {AP:20, frame:391, second:6.52},
  {AP:21, frame:382, second:6.37},
  {AP:22, frame:374, second:6.23},
  {AP:23, frame:366, second:6.1},
  {AP:24, frame:359, second:5.98},
  {AP:25, frame:351, second:5.85},
  {AP:26, frame:344, second:5.73},
  {AP:27, frame:337, second:5.62},
  {AP:28, frame:330, second:5.5},
  {AP:29, frame:323, second:5.38},
  {AP:30, frame:317, second:5.28},
  {AP:31, frame:310, second:5.17},
  {AP:32, frame:304, second:5.07},
  {AP:33, frame:298, second:4.97},
  {AP:34, frame:293, second:4.88},
  {AP:35, frame:287, second:4.78},
  {AP:36, frame:282, second:4.7},
  {AP:37, frame:277, second:4.62},
  {AP:38, frame:272, second:4.53},
  {AP:39, frame:267, second:4.45},
  {AP:41, frame:259, second:4.32},
  {AP:42, frame:255, second:4.25},
  {AP:44, frame:247, second:4.12},
  {AP:45, frame:244, second:4.07},
  {AP:47, frame:238, second:3.97},
  {AP:48, frame:235, second:3.92},
  {AP:51, frame:228, second:3.8},
  {AP:54, frame:223, second:3.72},
  {AP:57, frame:220, second:3.67},
];

export const InkRes = [
  {AP:0,FBTD:0,DPF:0.3,DL:0.4,RS:	0.24},
  {AP:3,FBTD:10,DPF:0.2,DL:0.3807,RS:0.3343},
  {AP:6,FBTD:15,DPF:0.2,DL:0.3623,RS:0.3942},
  {AP:9,FBTD:19,DPF:0.2,DL:0.345,RS:0.444},
  {AP:10,FBTD:20,DPF:0.2,DL:0.3394,RS:0.459},
  {AP:12,FBTD:22,DPF:0.2,DL:0.3286,RS:0.4872},
  {AP:13,FBTD:23,DPF:0.2,DL:0.3233,RS:0.5005},
  {AP:15,FBTD:24,DPF:0.2,DL:0.3131,RS:0.5255},
  {AP:16,FBTD:25,DPF:0.2,DL:0.3082,RS:0.5374},
  {AP:18,FBTD:27,DPF:0.2,DL:0.2987,RS:0.5598},
  {AP:19,FBTD:27,DPF:0.2,DL:0.2941,RS:0.5705},
  {AP:20,FBTD:28,DPF:0.2,DL:0.2896,RS:0.5808},
  {AP:21,FBTD:29,DPF:0.2,DL:0.2852,RS:0.5907},
  {AP:22,FBTD:29,DPF:0.2,DL:0.2809,RS:0.6003},
  {AP:23,FBTD:30,DPF:0.2,DL:0.2768,RS:0.6095},
  {AP:24,FBTD:30,DPF:0.2,DL:0.2727,RS:0.6185},
  {AP:25,FBTD:31,DPF:0.2,DL:0.2687,RS:0.6271},
  {AP:26,FBTD:32,DPF:0.2,DL:0.2649,RS:0.6354},
  {AP:27,FBTD:32,DPF:0.1,DL:0.2612,RS:0.6435},
  {AP:28,FBTD:32,DPF:0.1,DL:0.2575,RS:0.6512},
  {AP:29,FBTD:33,DPF:0.1,DL:0.254,RS:0.6587},
  {AP:30,FBTD:33,DPF:0.1,DL:0.2506,RS:0.6659},
  {AP:31,FBTD:34,DPF:0.1,DL:0.2473,RS:0.6728},
  {AP:32,FBTD:34,DPF:0.1,DL:0.2441,RS:0.6795},
  {AP:33,FBTD:35,DPF:0.1,DL:0.241,RS:0.6859},
  {AP:34,FBTD:35,DPF:0.1,DL:0.238,RS:0.692},
  {AP:35,FBTD:35,DPF:0.1,DL:0.2352,RS:0.6979},
  {AP:36,FBTD:36,DPF:0.1,DL:0.2324,RS:0.7036},
  {AP:37,FBTD:36,DPF:0.1,DL:0.2297,RS:0.709},
  {AP:38,FBTD:36,DPF:0.1,DL:0.2272,RS:0.7141},
  {AP:39,FBTD:37,DPF:0.1,DL:0.2247,RS:0.7191},
  {AP:41,FBTD:37,DPF:0.1,DL:0.2202,RS:0.7282},
  {AP:42,FBTD:37,DPF:0.1,DL:0.2181,RS:0.7324},
  {AP:44,FBTD:38,DPF:0.1,DL:0.2141,RS:0.7402},
  {AP:45,FBTD:38,DPF:0.1,DL:0.2124,RS:0.7438},
  {AP:47,FBTD:38,DPF:0.1,DL:0.2091,RS:0.7502},
  {AP:48,FBTD:39,DPF:0.1,DL:0.2076,RS:0.7531},
  {AP:51,FBTD:39,DPF:0.1,DL:0.2039,RS:0.7605},
  {AP:54,FBTD:39,DPF:0.1,DL:0.2011,RS:0.7659},
  {AP:57,FBTD:39,DPF:0.1,DL:0.2,RS:0.768}
];

export const inkSaverMain = {
  sploosh : [
    {AP:0,IC:0.008},{AP:3,IC:0.00765},{AP:6,IC:0.00732},{AP:9,IC:0.00701},{AP:10,IC:0.00691},{AP:12,IC:0.00671},{AP:13,IC:0.00662},{AP:15,IC:0.00644},{AP:16,IC:0.00635},{AP:18,IC:0.00618},{AP:19,IC:0.00609},{AP:20,IC:0.00601},{AP:21,IC:0.00593},{AP:22,IC:0.00586},{AP:23,IC:0.00578},{AP:24,IC:0.00571},{AP:25,IC:0.00564},{AP:26,IC:0.00557},{AP:27,IC:0.0055},{AP:28,IC:0.00544},{AP:29,IC:0.00537},{AP:30,IC:0.00531},{AP:31,IC:0.00525},{AP:32,IC:0.00519},{AP:33,IC:0.00514},{AP:34,IC:0.00508},{AP:35,IC:0.00503},{AP:36,IC:0.00498},{AP:37,IC:0.00494},{AP:38,IC:0.00489},{AP:39,IC:0.00485},{AP:41,IC:0.00476},{AP:42,IC:0.00473},{AP:44,IC:0.00465},{AP:45,IC:0.00462},{AP:47,IC:0.00456},{AP:48,IC:0.00454},{AP:51,IC:0.00447},{AP:54,IC:0.00442},{AP:57,IC:0.0044}
  ],
  splash : [
    {AP:0, IC:0.008},{AP:3,IC:0.00765},{AP:6,IC:0.00732},{AP:9,IC:0.00701},{AP:10,IC:0.00691},{AP:12,IC:0.00671},{AP:13,IC:0.00662},{AP:15,IC:0.00644},{AP:16,IC:0.00635},{AP:18,IC:0.00618},{AP:19,IC:0.00609},{AP:20,IC:0.00601},{AP:21,IC:0.00593},{AP:22,IC:0.00586},{AP:23,IC:0.00578},{AP:24,IC:0.00571},{AP:25,IC:0.00564},{AP:26,IC:0.00557},{AP:27,IC:0.0055},{AP:28,IC:0.00544},{AP:29,IC:0.00537},{AP:30,IC:0.00531},{AP:31,IC:0.00525},{AP:32,IC:0.00519},{AP:33,IC:0.00514},{AP:34,IC:0.00508},{AP:35,IC:0.00503},{AP:36,IC:0.00498},{AP:37,IC:0.00494},{AP:38,IC:0.00489},{AP:39,IC:0.00485},{AP:41,IC:0.00476},{AP:42,IC:0.00473},{AP:44,IC:0.00465},{AP:45,IC:0.00462},{AP:47,IC:0.00456},{AP:48,IC:0.00454},{AP:51,IC:0.00447},{AP:54,IC:0.00442},{AP:57,IC:0.0044}
  ],
  Jr : [
    {AP:0,IC:0.0043},{AP:3,IC:0.00411},{AP:6,IC:0.00394},{AP:9,IC:0.00377},{AP:10,IC:0.00371},{AP:12,IC:0.00361},{AP:13,IC:0.00356},{AP:15,IC:0.00346},{AP:16,IC:0.00341},{AP:18,IC:0.00332},{AP:19,IC:0.00328},{AP:20,IC:0.00323},{AP:21,IC:0.00319},{AP:22,IC:0.00315},{AP:23,IC:0.00311},{AP:24,IC:0.00307},{AP:25,IC:0.00303},{AP:26,IC:0.00299},{AP:27,IC:0.00296},{AP:28,IC:0.00292},{AP:29,IC:0.00289},{AP:30,IC:0.00285},{AP:31,IC:0.00282},{AP:32,IC:0.00279},{AP:33,IC:0.00276},{AP:34,IC:0.00273},{AP:35,IC:0.00271},{AP:36,IC:0.00268},{AP:37,IC:0.00265},{AP:38,IC:0.00263},{AP:39,IC:0.0026},{AP:41,IC:0.00256},{AP:42,IC:0.00254},{AP:44,IC:0.0025},{AP:45,IC:0.00248},{AP:47,IC:0.00245},{AP:48,IC:0.00244},{AP:51,IC:0.0024},{AP:54,IC:0.00238},{AP:57,IC:0.00237}
  ],
  aerospray : [
    {AP:0,IC:0.0055},{AP:3,IC:0.00526},{AP:6,IC:0.00503},{AP:9,IC:0.00482},{AP:10,IC:0.00475},{AP:12,IC:0.00462},{AP:13,IC:0.00455},{AP:15,IC:0.00443},{AP:16,IC:0.00436},{AP:18,IC:0.00425},{AP:19,IC:0.00419},{AP:20,IC:0.00413},{AP:21,IC:0.00408},{AP:22,IC:0.00403},{AP:23,IC:0.00397},{AP:24,IC:0.00392},{AP:25,IC:0.00388},{AP:26,IC:0.00383},{AP:27,IC:0.00378},{AP:28,IC:0.00374},{AP:29,IC:0.00369},{AP:30,IC:0.00365},{AP:31,IC:0.00361},{AP:32,IC:0.00357},{AP:33,IC:0.00353},{AP:34,IC:0.0035},{AP:35,IC:0.00346},{AP:36,IC:0.00343},{AP:37,IC:0.00339},{AP:38,IC:0.00336},{AP:39,IC:0.00333},{AP:41,IC:0.00327},{AP:42,IC:0.00325},{AP:44,IC:0.0032},{AP:45,IC:0.00318},{AP:47,IC:0.00314},{AP:48,IC:0.00312},{AP:51,IC:0.00307},{AP:54,IC:0.00304},{AP:57,IC:0.00302} 
  ],
  gal52 : [
    {AP:0,IC:0.013},{AP:3,IC:0.01244},{AP:6,IC:0.0119},{AP:9,IC:0.01139},{AP:10,IC:0.01123},{AP:12,IC:0.01091},{AP:13,IC:0.01076},{AP:15,IC:0.01046},{AP:16,IC:0.01032},{AP:18,IC:0.01004},{AP:19,IC:0.0099},{AP:20,IC:0.00977},{AP:21,IC:0.00964},{AP:22,IC:0.00952},{AP:23,IC:0.0094},{AP:24,IC:0.00928},{AP:25,IC:0.00916},{AP:26,IC:0.00905},{AP:27,IC:0.00894},{AP:28,IC:0.00883},{AP:29,IC:0.00873},{AP:30,IC:0.00863},{AP:31,IC:0.00853},{AP:32,IC:0.00844},{AP:33,IC:0.00835},{AP:34,IC:0.00826},{AP:35,IC:0.00818},{AP:36,IC:0.0081},{AP:37,IC:0.00802},{AP:38,IC:0.00794},{AP:39,IC:0.00787},{AP:41,IC:0.00774},{AP:42,IC:0.00768},{AP:44,IC:0.00756},{AP:45,IC:0.00751},{AP:47,IC:0.00742},{AP:48,IC:0.00737},{AP:51,IC:0.00726},{AP:54,IC:0.00718},{AP:57,IC:0.00715}
  ],
  Nzap : [
    {AP:0,IC:0.008},{AP:3,IC:0.00765},{AP:6,IC:0.00732},{AP:9,IC:0.00701},{AP:10,IC:0.00691},{AP:12,IC:0.00671},{AP:13,IC:0.00662},{AP:15,IC:0.00644},{AP:16,IC:0.00635},{AP:18,IC:0.00618},{AP:19,IC:0.00609},{AP:20,IC:0.00601},{AP:21,IC:0.00593},{AP:22,IC:0.00586},{AP:23,IC:0.00578},{AP:24,IC:0.00571},{AP:25,IC:0.00564},{AP:26,IC:0.00557},{AP:27,IC:0.0055},{AP:28,IC:0.00544},{AP:29,IC:0.00537},{AP:30,IC:0.00531},{AP:31,IC:0.00525},{AP:32,IC:0.00519},{AP:33,IC:0.00514},{AP:34,IC:0.00508},{AP:35,IC:0.00503},{AP:36,IC:0.00498},{AP:37,IC:0.00494},{AP:38,IC:0.00489},{AP:39,IC:0.00485},{AP:41,IC:0.00476},{AP:42,IC:0.00473},{AP:44,IC:0.00465},{AP:45,IC:0.00462},{AP:47,IC:0.00456},{AP:48,IC:0.00454},{AP:51,IC:0.00447},{AP:54,IC:0.00442},{AP:57,IC:0.0044}
  ],
  Pro : [
    {AP:0,IC:0.02},{AP:3,IC:0.01821},{AP:6,IC:0.01708},{AP:9,IC:0.01614},{AP:10,IC:0.01585},{AP:12,IC:0.01532},{AP:13,IC:0.01507},{AP:15,IC:0.01459},{AP:16,IC:0.01437},{AP:18,IC:0.01394},{AP:19,IC:0.01374},{AP:20,IC:0.01355},{AP:21,IC:0.01336},{AP:22,IC:0.01318},{AP:23,IC:0.013},{AP:24,IC:0.01283},{AP:25,IC:0.01267},{AP:26,IC:0.01251},{AP:27,IC:0.01236},{AP:28,IC:0.01221},{AP:29,IC:0.01207},{AP:30,IC:0.01193},{AP:31,IC:0.0118},{AP:32,IC:0.01168},{AP:33,IC:0.01156},{AP:34,IC:0.01144},{AP:35,IC:0.01133},{AP:36,IC:0.01122},{AP:37,IC:0.01112},{AP:38,IC:0.01102},{AP:39,IC:0.01093},{AP:41,IC:0.01075},{AP:42,IC:0.01067},{AP:44,IC:0.01053},{AP:45,IC:0.01046},{AP:47,IC:0.01034},{AP:48,IC:0.01028},{AP:51,IC:0.01014},{AP:54,IC:0.01004},{AP:57,IC:0.01}
  ],
  JetSquelcher : [
    {AP:0,IC:0.016},{AP:3,IC:0.0153},{AP:6,IC:0.01464},{AP:9,IC:0.01402},{AP:10,IC:0.01382},{AP:12,IC:0.01343},{AP:13,IC:0.01324},{AP:15,IC:0.01287},{AP:16,IC:0.0127},{AP:18,IC:0.01235},{AP:19,IC:0.01219},{AP:20,IC:0.01203},{AP:21,IC:0.01187},{AP:22,IC:0.01171},{AP:23,IC:0.01156},{AP:24,IC:0.01142},{AP:25,IC:0.01128},{AP:26,IC:0.01114},{AP:27,IC:0.011},{AP:28,IC:0.01087},{AP:29,IC:0.01074},{AP:30,IC:0.01062},{AP:31,IC:0.0105},{AP:32,IC:0.01039},{AP:33,IC:0.01028},{AP:34,IC:0.01017},{AP:35,IC:0.01007},{AP:36,IC:0.00997},{AP:37,IC:0.00987},{AP:38,IC:0.00978},{AP:39,IC:0.00969},{AP:41,IC:0.00953},{AP:42,IC:0.00945},{AP:44,IC:0.00931},{AP:45,IC:0.00924},{AP:47,IC:0.00913},{AP:48,IC:0.00907},{AP:51,IC:0.00894},{AP:54,IC:0.00884},{AP:57,IC:0.0088}
  ],
  gal96 : [
    {AP:0,IC:0.025},{AP:3,IC:0.02391},{AP:6,IC:0.02288},{AP:9,IC:0.0219},{AP:10,IC:0.02159},{AP:12,IC:0.02098},{AP:13,IC:0.02069},{AP:15,IC:0.02011},{AP:16,IC:0.01984},{AP:18,IC:0.0193},{AP:19,IC:0.01904},{AP:20,IC:0.01879},{AP:21,IC:0.01854},{AP:22,IC:0.0183},{AP:23,IC:0.01807},{AP:24,IC:0.01784},{AP:25,IC:0.01762},{AP:26,IC:0.0174},{AP:27,IC:0.01719},{AP:28,IC:0.01699},{AP:29,IC:0.01679},{AP:30,IC:0.0166},{AP:31,IC:0.01641},{AP:32,IC:0.01623},{AP:33,IC:0.01606},{AP:34,IC:0.01589},{AP:35,IC:0.01573},{AP:36,IC:0.01557},{AP:37,IC:0.01542},{AP:38,IC:0.01528},{AP:39,IC:0.01514},{AP:41,IC:0.01488},{AP:42,IC:0.01477},{AP:44,IC:0.01455},{AP:45,IC:0.01444},{AP:47,IC:0.01426},{AP:48,IC:0.01418},{AP:51,IC:0.01397},{AP:54,IC:0.01381},{AP:57,IC:0.01375} 
  ],
  Luna : [
    {AP:0,IC:0.075},{AP:3,IC:0.0683},{AP:6,IC:0.06405},{AP:9,IC:0.06051},{AP:10,IC:0.05944},{AP:12,IC:0.05744},{AP:13,IC:0.0565},{AP:15,IC:0.05472},{AP:16,IC:0.05388},{AP:18,IC:0.05228},{AP:19,IC:0.05153},{AP:20,IC:0.0508},{AP:21,IC:0.05009},{AP:22,IC:0.04941},{AP:23,IC:0.04875},{AP:24,IC:0.04812},{AP:25,IC:0.04751},{AP:26,IC:0.04692},{AP:27,IC:0.04635},{AP:28,IC:0.04579},{AP:29,IC:0.04526},{AP:30,IC:0.04475},{AP:31,IC:0.04426},{AP:32,IC:0.04379},{AP:33,IC:0.04333},{AP:34,IC:0.0429},{AP:35,IC:0.04248},{AP:36,IC:0.04208},{AP:37,IC:0.04169},{AP:38,IC:0.04133},{AP:39,IC:0.04098},{AP:41,IC:0.04033},{AP:42,IC:0.04003},{AP:44,IC:0.03947},{AP:45,IC:0.03922},{AP:47,IC:0.03876},{AP:48,IC:0.03856},{AP:51,IC:0.03803},{AP:54,IC:0.03765},{AP:57,IC:0.0375}
  ],
  Blaster : [
    {AP:0,IC:0.1},{AP:3,IC:0.09565},{AP:6,IC:0.09153},{AP:9,IC:0.08762},{AP:10,IC:0.08637},{AP:12,IC:0.08393},{AP:13,IC:0.08275},{AP:15,IC:0.08046},{AP:16,IC:0.07935},{AP:18,IC:0.07721},{AP:19,IC:0.07617},{AP:20,IC:0.07516},{AP:21,IC:0.07417},{AP:22,IC:0.07321},{AP:23,IC:0.07227},{AP:24,IC:0.07136},{AP:25,IC:0.07047},{AP:26,IC:0.0696},{AP:27,IC:0.06876},{AP:28,IC:0.06795},{AP:29,IC:0.06715},{AP:30,IC:0.06639},{AP:31,IC:0.06564},{AP:32,IC:0.06492},{AP:33,IC:0.06423},{AP:34,IC:0.06356},{AP:35,IC:0.06291},{AP:36,IC:0.06229},{AP:37,IC:0.06169},{AP:38,IC:0.06111},{AP:39,IC:0.06057},{AP:41,IC:0.05954},{AP:42,IC:0.05906},{AP:44,IC:0.05818},{AP:45,IC:0.05778},{AP:47,IC:0.05704},{AP:48,IC:0.05671},{AP:51,IC:0.05587},{AP:54,IC:0.05524},{AP:57,IC:0.055} 
  ],
  ClashBlaster : [
    {AP:0,IC:0.04},{AP:3,IC:0.03826},{AP:6,IC:0.03661},{AP:9,IC:0.03505},{AP:10,IC:0.03455},{AP:12,IC:0.03357},{AP:13,IC:0.0331},{AP:15,IC:0.03218},{AP:16,IC:0.03174},{AP:18,IC:0.03088},{AP:19,IC:0.03047},{AP:20,IC:0.03006},{AP:21,IC:0.02967},{AP:22,IC:0.02928},{AP:23,IC:0.02891},{AP:24,IC:0.02854},{AP:25,IC:0.02819},{AP:26,IC:0.02784},{AP:27,IC:0.0275},{AP:28,IC:0.02718},{AP:29,IC:0.02686},{AP:30,IC:0.02655},{AP:31,IC:0.02626},{AP:32,IC:0.02597},{AP:33,IC:0.02569},{AP:34,IC:0.02542},{AP:35,IC:0.02516},{AP:36,IC:0.02491},{AP:37,IC:0.02468},{AP:38,IC:0.02445},{AP:39,IC:0.02423},{AP:41,IC:0.02382},{AP:42,IC:0.02363},{AP:44,IC:0.02327},{AP:45,IC:0.02311},{AP:47,IC:0.02282},{AP:48,IC:0.02269},{AP:51,IC:0.02235},{AP:54,IC:0.0221},{AP:57,IC:0.022}
  ],
  SplatterShot : [
    {AP:0,IC:0.0092},{AP:3,IC:0.0088},{AP:6,IC:0.00842},{AP:9,IC:0.00806},{AP:10,IC:0.00795},{AP:12,IC:0.00772},{AP:13,IC:0.00761},{AP:15,IC:0.0074},{AP:16,IC:0.0073},{AP:18,IC:0.0071},{AP:19,IC:0.00701},{AP:20,IC:0.00691},{AP:21,IC:0.00682},{AP:22,IC:0.00674},{AP:23,IC:0.00665},{AP:24,IC:0.00656},{AP:25,IC:0.00648},{AP:26,IC:0.0064},{AP:27,IC:0.00633},{AP:28,IC:0.00625},{AP:29,IC:0.00618},{AP:30,IC:0.00611},{AP:31,IC:0.00604},{AP:32,IC:0.00597},{AP:33,IC:0.00591},{AP:34,IC:0.00585},{AP:35,IC:0.00579},{AP:36,IC:0.00573},{AP:37,IC:0.00568},{AP:38,IC:0.00562},{AP:39,IC:0.00557},{AP:41,IC:0.00548},{AP:42,IC:0.00543},{AP:44,IC:0.00535},{AP:45,IC:0.00532},{AP:47,IC:0.00525},{AP:48,IC:0.00522},{AP:51,IC:0.00514},{AP:54,IC:0.00508},{AP:57,IC:0.00506} 
  ],
  RapidBlaster : [
    {AP:0,IC:0.07},{AP:3,IC:0.06696},{AP:6,IC:0.06407},{AP:9,IC:0.06133},{AP:10,IC:0.06046},{AP:12,IC:0.05875},{AP:13,IC:0.05792},{AP:15,IC:0.05632},{AP:16,IC:0.05555},{AP:18,IC:0.05404},{AP:19,IC:0.05332},{AP:20,IC:0.05261},{AP:21,IC:0.05192},{AP:22,IC:0.05125},{AP:23,IC:0.05059},{AP:24,IC:0.04995},{AP:25,IC:0.04933},{AP:26,IC:0.04872},{AP:27,IC:0.04813},{AP:28,IC:0.04756},{AP:29,IC:0.04701},{AP:30,IC:0.04647},{AP:31,IC:0.04595},{AP:32,IC:0.04545},{AP:33,IC:0.04496},{AP:34,IC:0.04449},{AP:35,IC:0.04404},{AP:36,IC:0.0436},{AP:37,IC:0.04318},{AP:38,IC:0.04278},{AP:39,IC:0.0424},{AP:41,IC:0.04168},{AP:42,IC:0.04134},{AP:44,IC:0.04073},{AP:45,IC:0.04045},{AP:47,IC:0.03993},{AP:48,IC:0.0397},{AP:51,IC:0.03911},{AP:54,IC:0.03867},{AP:57,IC:0.0385}
  ],
  Dynamo : [
    {AP:0,IC:0.18},{AP:3,IC:0.16393},{AP:6,IC:0.15371},{AP:9,IC:0.14523},{AP:10,IC:0.14267},{AP:12,IC:0.13786},{AP:13,IC:0.1356},{AP:15,IC:0.13133},{AP:16,IC:0.12931},{AP:18,IC:0.12548},{AP:19,IC:0.12367},{AP:20,IC:0.12192},{AP:21,IC:0.12022},{AP:22,IC:0.11859},{AP:23,IC:0.11701},{AP:24,IC:0.11549},{AP:25,IC:0.11402},{AP:26,IC:0.1126},{AP:27,IC:0.11123},{AP:28,IC:0.10991},{AP:29,IC:0.10863},{AP:30,IC:0.10741},{AP:31,IC:0.10623},{AP:32,IC:0.10509},{AP:33,IC:0.104},{AP:34,IC:0.10295},{AP:35,IC:0.10195},{AP:36,IC:0.10099},{AP:37,IC:0.10006},{AP:38,IC:0.09918},{AP:39,IC:0.09834},{AP:41,IC:0.09678},{AP:42,IC:0.09606},{AP:44,IC:0.09474},{AP:45,IC:0.09413},{AP:47,IC:0.09303},{AP:48,IC:0.09254},{AP:51,IC:0.09128},{AP:54,IC:0.09035},{AP:57,IC:0.09}
  ],
  L3Nozzle : [
    {AP:0,IC:0.0115},{AP:3,IC:0.011},{AP:6,IC:0.01053},{AP:9,IC:0.01008},{AP:10,IC:0.00993},{AP:12,IC:0.00965},{AP:13,IC:0.00952},{AP:15,IC:0.00925},{AP:16,IC:0.00913},{AP:18,IC:0.00888},{AP:19,IC:0.00876},{AP:20,IC:0.00864},{AP:21,IC:0.00853},{AP:22,IC:0.00842},{AP:23,IC:0.00831},{AP:24,IC:0.00821},{AP:25,IC:0.0081},{AP:26,IC:0.008},{AP:27,IC:0.00791},{AP:28,IC:0.00781},{AP:29,IC:0.00772},{AP:30,IC:0.00763},{AP:31,IC:0.00755},{AP:32,IC:0.00747},{AP:33,IC:0.00739},{AP:34,IC:0.00731},{AP:35,IC:0.00723},{AP:36,IC:0.00716},{AP:37,IC:0.00709},{AP:38,IC:0.00703},{AP:39,IC:0.00696},{AP:41,IC:0.00685},{AP:42,IC:0.00679},{AP:44,IC:0.00669},{AP:45,IC:0.00664},{AP:47,IC:0.00656},{AP:48,IC:0.00652},{AP:51,IC:0.00642},{AP:54,IC:0.00635},{AP:57,IC:0.00633} 
  ],
  Rblaster : [
    {AP:0,IC:0.08},{AP:3,IC:0.07652},{AP:6,IC:0.07322},{AP:9,IC:0.0701},{AP:10,IC:0.06909},{AP:12,IC:0.06714},{AP:13,IC:0.0662},{AP:15,IC:0.06437},{AP:16,IC:0.06348},{AP:18,IC:0.06177},{AP:19,IC:0.06094},{AP:20,IC:0.06013},{AP:21,IC:0.05934},{AP:22,IC:0.05857},{AP:23,IC:0.05782},{AP:24,IC:0.05709},{AP:25,IC:0.05638},{AP:26,IC:0.05568},{AP:27,IC:0.05501},{AP:28,IC:0.05436},{AP:29,IC:0.05372},{AP:30,IC:0.05311},{AP:31,IC:0.05251},{AP:32,IC:0.05194},{AP:33,IC:0.05138},{AP:34,IC:0.05084},{AP:35,IC:0.05033},{AP:36,IC:0.04983},{AP:37,IC:0.04935},{AP:38,IC:0.04889},{AP:39,IC:0.04845},{AP:41,IC:0.04763},{AP:42,IC:0.04725},{AP:44,IC:0.04655},{AP:45,IC:0.04622},{AP:47,IC:0.04564},{AP:48,IC:0.04537},{AP:51,IC:0.04469},{AP:54,IC:0.04419},{AP:57,IC:0.044} 
  ],
  Squeezer : [
    {AP:0,IC:0.022},{AP:3,IC:0.02104},{AP:6,IC:0.02014},{AP:9,IC:0.01928},{AP:10,IC:0.019},{AP:12,IC:0.01846},{AP:13,IC:0.0182},{AP:15,IC:0.0177},{AP:16,IC:0.01746},{AP:18,IC:0.01699},{AP:19,IC:0.01676},{AP:20,IC:0.01654},{AP:21,IC:0.01632},{AP:22,IC:0.01611},{AP:23,IC:0.0159},{AP:24,IC:0.0157},{AP:25,IC:0.0155},{AP:26,IC:0.01531},{AP:27,IC:0.01513},{AP:28,IC:0.01495},{AP:29,IC:0.01477},{AP:30,IC:0.0146},{AP:31,IC:0.01444},{AP:32,IC:0.01428},{AP:33,IC:0.01413},{AP:34,IC:0.01398},{AP:35,IC:0.01384},{AP:36,IC:0.0137},{AP:37,IC:0.01357},{AP:38,IC:0.01345},{AP:39,IC:0.01332},{AP:41,IC:0.0131},{AP:42,IC:0.01299},{AP:44,IC:0.0128},{AP:45,IC:0.01271},{AP:47,IC:0.01255},{AP:48,IC:0.01248},{AP:51,IC:0.01229},{AP:54,IC:0.01215},{AP:57,IC:0.0121}
  ],
  H3Nozzle : [
    {AP:0,IC:0.0225},{AP:3,IC:0.02049},{AP:6,IC:0.01921},{AP:9,IC:0.01815},{AP:10,IC:0.01783},{AP:12,IC:0.01723},{AP:13,IC:0.01695},{AP:15,IC:0.01642},{AP:16,IC:0.01616},{AP:18,IC:0.01569},{AP:19,IC:0.01546},{AP:20,IC:0.01524},{AP:21,IC:0.01503},{AP:22,IC:0.01482},{AP:23,IC:0.01463},{AP:24,IC:0.01444},{AP:25,IC:0.01425},{AP:26,IC:0.01407},{AP:27,IC:0.0139},{AP:28,IC:0.01374},{AP:29,IC:0.01358},{AP:30,IC:0.01343},{AP:31,IC:0.01328},{AP:32,IC:0.01314},{AP:33,IC:0.013},{AP:34,IC:0.01287},{AP:35,IC:0.01274},{AP:36,IC:0.01262},{AP:37,IC:0.01251},{AP:38,IC:0.0124},{AP:39,IC:0.01229},{AP:41,IC:0.0121},{AP:42,IC:0.01201},{AP:44,IC:0.01184},{AP:45,IC:0.01177},{AP:47,IC:0.01163},{AP:48,IC:0.01157},{AP:51,IC:0.01141},{AP:54,IC:0.01129},{AP:57,IC:0.01125}
  ],
  SplatRoller : [
    {AP:0,IC:0.085},{AP:3,IC:0.08131},{AP:6,IC:0.0778},{AP:9,IC:0.07448},{AP:10,IC:0.07341},{AP:12,IC:0.07134},{AP:13,IC:0.07034},{AP:15,IC:0.06839},{AP:16,IC:0.06745},{AP:18,IC:0.06563},{AP:19,IC:0.06475},{AP:20,IC:0.06389},{AP:21,IC:0.06305},{AP:22,IC:0.06223},{AP:23,IC:0.06143},{AP:24,IC:0.06065},{AP:25,IC:0.0599},{AP:26,IC:0.05916},{AP:27,IC:0.05845},{AP:28,IC:0.05775},{AP:29,IC:0.05708},{AP:30,IC:0.05643},{AP:31,IC:0.05579},{AP:32,IC:0.05518},{AP:33,IC:0.05459},{AP:34,IC:0.05402},{AP:35,IC:0.05347},{AP:36,IC:0.05294},{AP:37,IC:0.05244},{AP:38,IC:0.05195},{AP:39,IC:0.05148},{AP:41,IC:0.05061},{AP:42,IC:0.0502},{AP:44,IC:0.04946},{AP:45,IC:0.04911},{AP:47,IC:0.04849},{AP:48,IC:0.04821},{AP:51,IC:0.04749},{AP:54,IC:0.04695},{AP:57,IC:0.04675}
  ],
  Carbon : [
    {AP:0,IC:0.0396},{AP:3,IC:0.03788},{AP:6,IC:0.03624},{AP:9,IC:0.0347},{AP:10,IC:0.0342},{AP:12,IC:0.03324},{AP:13,IC:0.03277},{AP:15,IC:0.03186},{AP:16,IC:0.03142},{AP:18,IC:0.03057},{AP:19,IC:0.03016},{AP:20,IC:0.02976},{AP:21,IC:0.02937},{AP:22,IC:0.02899},{AP:23,IC:0.02862},{AP:24,IC:0.02826},{AP:25,IC:0.02791},{AP:26,IC:0.02756},{AP:27,IC:0.02723},{AP:28,IC:0.02691},{AP:29,IC:0.02659},{AP:30,IC:0.02629},{AP:31,IC:0.02599},{AP:32,IC:0.02571},{AP:33,IC:0.02543},{AP:34,IC:0.02517},{AP:35,IC:0.02491},{AP:36,IC:0.02467},{AP:37,IC:0.02443},{AP:38,IC:0.0242},{AP:39,IC:0.02398},{AP:41,IC:0.02358},{AP:42,IC:0.02339},{AP:44,IC:0.02304},{AP:45,IC:0.02288},{AP:47,IC:0.02259},{AP:48,IC:0.02246},{AP:51,IC:0.02212},{AP:54,IC:0.02187},{AP:57,IC:0.02178}
  ],
  RangeBlaster : [
    {AP:0,IC:0.11},{AP:3,IC:0.10522},{AP:6,IC:0.10068},{AP:9,IC:0.09638},{AP:10,IC:0.095},{AP:12,IC:0.09232},{AP:13,IC:0.09102},{AP:15,IC:0.0885},{AP:16,IC:0.08729},{AP:18,IC:0.08493},{AP:19,IC:0.08379},{AP:20,IC:0.08268},{AP:21,IC:0.08159},{AP:22,IC:0.08053},{AP:23,IC:0.0795},{AP:24,IC:0.07849},{AP:25,IC:0.07752},{AP:26,IC:0.07656},{AP:27,IC:0.07564},{AP:28,IC:0.07474},{AP:29,IC:0.07387},{AP:30,IC:0.07302},{AP:31,IC:0.07221},{AP:32,IC:0.07141},{AP:33,IC:0.07065},{AP:34,IC:0.06991},{AP:35,IC:0.0692},{AP:36,IC:0.06852},{AP:37,IC:0.06786},{AP:38,IC:0.06723},{AP:39,IC:0.06662},{AP:41,IC:0.06549},{AP:42,IC:0.06497},{AP:44,IC:0.064},{AP:45,IC:0.06356},{AP:47,IC:0.06275},{AP:48,IC:0.06238},{AP:51,IC:0.06145},{AP:54,IC:0.06076},{AP:57,IC:0.0605}
  ],
  Flingza : [
    {AP:0,IC:0.08},{AP:3,IC:0.07652},{AP:6,IC:0.07322},{AP:9,IC:0.0701},{AP:10,IC:0.06909},{AP:12,IC:0.06714},{AP:13,IC:0.0662},{AP:15,IC:0.06437},{AP:16,IC:0.06348},{AP:18,IC:0.06177},{AP:19,IC:0.06094},{AP:20,IC:0.06013},{AP:21,IC:0.05934},{AP:22,IC:0.05857},{AP:23,IC:0.05782},{AP:24,IC:0.05709},{AP:25,IC:0.05638},{AP:26,IC:0.05568},{AP:27,IC:0.05501},{AP:28,IC:0.05436},{AP:29,IC:0.05372},{AP:30,IC:0.05311},{AP:31,IC:0.05251},{AP:32,IC:0.05194},{AP:33,IC:0.05138},{AP:34,IC:0.05084},{AP:35,IC:0.05033},{AP:36,IC:0.04983},{AP:37,IC:0.04935},{AP:38,IC:0.04889},{AP:39,IC:0.04845},{AP:41,IC:0.04763},{AP:42,IC:0.04725},{AP:44,IC:0.04655},{AP:45,IC:0.04622},{AP:47,IC:0.04564},{AP:48,IC:0.04537},{AP:51,IC:0.04469},{AP:54,IC:0.04419},{AP:57,IC:0.044}
  ],
  Octobrush : [
    {AP:0,IC:0.032},{AP:3,IC:0.03061},{AP:6,IC:0.02929},{AP:9,IC:0.02804},{AP:10,IC:0.02764},{AP:12,IC:0.02686},{AP:13,IC:0.02648},{AP:15,IC:0.02575},{AP:16,IC:0.02539},{AP:18,IC:0.02471},{AP:19,IC:0.02437},{AP:20,IC:0.02405},{AP:21,IC:0.02374},{AP:22,IC:0.02343},{AP:23,IC:0.02313},{AP:24,IC:0.02283},{AP:25,IC:0.02255},{AP:26,IC:0.02227},{AP:27,IC:0.022},{AP:28,IC:0.02174},{AP:29,IC:0.02149},{AP:30,IC:0.02124},{AP:31,IC:0.02101},{AP:32,IC:0.02077},{AP:33,IC:0.02055},{AP:34,IC:0.02034},{AP:35,IC:0.02013},{AP:36,IC:0.01993},{AP:37,IC:0.01974},{AP:38,IC:0.01956},{AP:39,IC:0.01938},{AP:41,IC:0.01905},{AP:42,IC:0.0189},{AP:44,IC:0.01862},{AP:45,IC:0.01849},{AP:47,IC:0.01825},{AP:48,IC:0.01815},{AP:51,IC:0.01788},{AP:54,IC:0.01768},{AP:57,IC:0.0176}
  ],
  Inkbrush : [
    {AP:0,IC:0.02},{AP:3,IC:0.01913},{AP:6,IC:0.01831},{AP:9,IC:0.01752},{AP:10,IC:0.01727},{AP:12,IC:0.01679},{AP:13,IC:0.01655},{AP:15,IC:0.01609},{AP:16,IC:0.01587},{AP:18,IC:0.01544},{AP:19,IC:0.01523},{AP:20,IC:0.01503},{AP:21,IC:0.01483},{AP:22,IC:0.01464},{AP:23,IC:0.01445},{AP:24,IC:0.01427},{AP:25,IC:0.01409},{AP:26,IC:0.01392},{AP:27,IC:0.01375},{AP:28,IC:0.01359},{AP:29,IC:0.01343},{AP:30,IC:0.01328},{AP:31,IC:0.01313},{AP:32,IC:0.01298},{AP:33,IC:0.01285},{AP:34,IC:0.01271},{AP:35,IC:0.01258},{AP:36,IC:0.01246},{AP:37,IC:0.01234},{AP:38,IC:0.01222},{AP:39,IC:0.01211},{AP:41,IC:0.01191},{AP:42,IC:0.01181},{AP:44,IC:0.01164},{AP:45,IC:0.01156},{AP:47,IC:0.01141},{AP:48,IC:0.01134},{AP:51,IC:0.01117},{AP:54,IC:0.01105},{AP:57,IC:0.011}
  ],
  ChargerMin : [
    {AP:0,IC:0.0225},{AP:3,IC:0.02152},{AP:6,IC:0.02059},{AP:9,IC:0.01971},{AP:10,IC:0.01943},{AP:12,IC:0.01888},{AP:13,IC:0.01862},{AP:15,IC:0.0181},{AP:16,IC:0.01785},{AP:18,IC:0.01737},{AP:19,IC:0.01714},{AP:20,IC:0.01691},{AP:21,IC:0.01669},{AP:22,IC:0.01647},{AP:23,IC:0.01626},{AP:24,IC:0.01606},{AP:25,IC:0.01586},{AP:26,IC:0.01566},{AP:27,IC:0.01547},{AP:28,IC:0.01529},{AP:29,IC:0.01511},{AP:30,IC:0.01494},{AP:31,IC:0.01477},{AP:32,IC:0.01461},{AP:33,IC:0.01445},{AP:34,IC:0.0143},{AP:35,IC:0.01415},{AP:36,IC:0.01401},{AP:37,IC:0.01388},{AP:38,IC:0.01375},{AP:39,IC:0.01363},{AP:41,IC:0.0134},{AP:42,IC:0.01329},{AP:44,IC:0.01309},{AP:45,IC:0.013},{AP:47,IC:0.01283},{AP:48,IC:0.01276},{AP:51,IC:0.01257},{AP:54,IC:0.01243},{AP:57,IC:0.01238}
  ],
  ChargerMax : [
    {AP:0,IC:0.18},{AP:3,IC:0.17218},{AP:6,IC:0.16475},{AP:9,IC:0.15771},{AP:10,IC:0.15546},{AP:12,IC:0.15107},{AP:13,IC:0.14895},{AP:15,IC:0.14483},{AP:16,IC:0.14283},{AP:18,IC:0.13897},{AP:19,IC:0.13711},{AP:20,IC:0.13529},{AP:21,IC:0.13351},{AP:22,IC:0.13178},{AP:23,IC:0.13009},{AP:24,IC:0.12845},{AP:25,IC:0.12684},{AP:26,IC:0.12529},{AP:27,IC:0.12377},{AP:28,IC:0.1223},{AP:29,IC:0.12088},{AP:30,IC:0.11949},{AP:31,IC:0.11815},{AP:32,IC:0.11686},{AP:33,IC:0.11561},{AP:34,IC:0.1144},{AP:35,IC:0.11324},{AP:36,IC:0.11212},{AP:37,IC:0.11104},{AP:38,IC:0.11001},{AP:39,IC:0.10902},{AP:41,IC:0.10717},{AP:42,IC:0.10631},{AP:44,IC:0.10473},{AP:45,IC:0.104},{AP:47,IC:0.10268},{AP:48,IC:0.10208},{AP:51,IC:0.10056},{AP:54,IC:0.09943},{AP:57,IC:0.099}
  ],
  Squiffer : [
    {AP:0,IC:0.105},{AP:3,IC:0.10044},{AP:6,IC:0.0961},{AP:9,IC:0.092},{AP:10,IC:0.09068},{AP:12,IC:0.08813},{AP:13,IC:0.08689},{AP:15,IC:0.08448},{AP:16,IC:0.08332},{AP:18,IC:0.08107},{AP:19,IC:0.07998},{AP:20,IC:0.07892},{AP:21,IC:0.07788},{AP:22,IC:0.07687},{AP:23,IC:0.07589},{AP:24,IC:0.07493},{AP:25,IC:0.07399},{AP:26,IC:0.07308},{AP:27,IC:0.0722},{AP:28,IC:0.07134},{AP:29,IC:0.07051},{AP:30,IC:0.0697},{AP:31,IC:0.06892},{AP:32,IC:0.06817},{AP:33,IC:0.06744},{AP:34,IC:0.06673},{AP:35,IC:0.06605},{AP:36,IC:0.0654},{AP:37,IC:0.06477},{AP:38,IC:0.06417},{AP:39,IC:0.06359},{AP:41,IC:0.06252},{AP:42,IC:0.06202},{AP:44,IC:0.06109},{AP:45,IC:0.06067},{AP:47,IC:0.0599},{AP:48,IC:0.05955},{AP:51,IC:0.05866},{AP:54,IC:0.058},{AP:57,IC:0.05775}
  ],
  Eliter : [
    {AP:0,IC:0.25},{AP:3,IC:0.22768},{AP:6,IC:0.21349},{AP:9,IC:0.20171},{AP:10,IC:0.19815},{AP:12,IC:0.19147},{AP:13,IC:0.18833},{AP:15,IC:0.1824},{AP:16,IC:0.1796},{AP:18,IC:0.17428},{AP:19,IC:0.17176},{AP:20,IC:0.16933},{AP:21,IC:0.16698},{AP:22,IC:0.16471},{AP:23,IC:0.16252},{AP:24,IC:0.1604},{AP:25,IC:0.15836},{AP:26,IC:0.15639},{AP:27,IC:0.15448},{AP:28,IC:0.15265},{AP:29,IC:0.15088},{AP:30,IC:0.14918},{AP:31,IC:0.14754},{AP:32,IC:0.14596},{AP:33,IC:0.14445},{AP:34,IC:0.14299},{AP:35,IC:0.1416},{AP:36,IC:0.14026},{AP:37,IC:0.13898},{AP:38,IC:0.13775},{AP:39,IC:0.13659},{AP:41,IC:0.13442},{AP:42,IC:0.13342},{AP:44,IC:0.13158},{AP:45,IC:0.13074},{AP:47,IC:0.12921},{AP:48,IC:0.12853},{AP:51,IC:0.12678},{AP:54,IC:0.12549},{AP:57,IC:0.125}
  ],
  Bamboo : [
    {AP:0,IC:0.084},{AP:3,IC:0.08035},{AP:6,IC:0.07688},{AP:9,IC:0.0736},{AP:10,IC:0.07255},{AP:12,IC:0.0705},{AP:13,IC:0.06951},{AP:15,IC:0.06759},{AP:16,IC:0.06665},{AP:18,IC:0.06485},{AP:19,IC:0.06398},{AP:20,IC:0.06313},{AP:21,IC:0.06231},{AP:22,IC:0.0615},{AP:23,IC:0.06071},{AP:24,IC:0.05994},{AP:25,IC:0.05919},{AP:26,IC:0.05847},{AP:27,IC:0.05776},{AP:28,IC:0.05707},{AP:29,IC:0.05641},{AP:30,IC:0.05576},{AP:31,IC:0.05514},{AP:32,IC:0.05453},{AP:33,IC:0.05395},{AP:34,IC:0.05339},{AP:35,IC:0.05284},{AP:36,IC:0.05232},{AP:37,IC:0.05182},{AP:38,IC:0.05134},{AP:39,IC:0.05087},{AP:41,IC:0.05001},{AP:42,IC:0.04961},{AP:44,IC:0.04887},{AP:45,IC:0.04853},{AP:47,IC:0.04792},{AP:48,IC:0.04764},{AP:51,IC:0.04693},{AP:54,IC:0.0464},{AP:57,IC:0.0462}
  ],
  GooTuber : [
    {AP:0,IC:0.15},{AP:3,IC:0.14348},{AP:6,IC:0.13729},{AP:9,IC:0.13143},{AP:10,IC:0.12955},{AP:12,IC:0.12589},{AP:13,IC:0.12412},{AP:15,IC:0.12069},{AP:16,IC:0.11903},{AP:18,IC:0.11581},{AP:19,IC:0.11426},{AP:20,IC:0.11274},{AP:21,IC:0.11126},{AP:22,IC:0.10982},{AP:23,IC:0.10841},{AP:24,IC:0.10704},{AP:25,IC:0.1057},{AP:26,IC:0.10441},{AP:27,IC:0.10314},{AP:28,IC:0.10192},{AP:29,IC:0.10073},{AP:30,IC:0.09958},{AP:31,IC:0.09846},{AP:32,IC:0.09738},{AP:33,IC:0.09634},{AP:34,IC:0.09533},{AP:35,IC:0.09436},{AP:36,IC:0.09343},{AP:37,IC:0.09253},{AP:38,IC:0.09167},{AP:39,IC:0.09085},{AP:41,IC:0.08931},{AP:42,IC:0.08859},{AP:44,IC:0.08727},{AP:45,IC:0.08667},{AP:47,IC:0.08557},{AP:48,IC:0.08507},{AP:51,IC:0.0838},{AP:54,IC:0.08286},{AP:57,IC:0.0825},
  ],
  Slosher : [
    {AP:0,IC:0.07},{AP:3,IC:0.06696},{AP:6,IC:0.06407},{AP:9,IC:0.06133},{AP:10,IC:0.06046},{AP:12,IC:0.05875},{AP:13,IC:0.05792},{AP:15,IC:0.05632},{AP:16,IC:0.05555},{AP:18,IC:0.05404},{AP:19,IC:0.05332},{AP:20,IC:0.05261},{AP:21,IC:0.05192},{AP:22,IC:0.05125},{AP:23,IC:0.05059},{AP:24,IC:0.04995},{AP:25,IC:0.04933},{AP:26,IC:0.04872},{AP:27,IC:0.04813},{AP:28,IC:0.04756},{AP:29,IC:0.04701},{AP:30,IC:0.04647},{AP:31,IC:0.04595},{AP:32,IC:0.04545},{AP:33,IC:0.04496},{AP:34,IC:0.04449},{AP:35,IC:0.04404},{AP:36,IC:0.0436},{AP:37,IC:0.04318},{AP:38,IC:0.04278},{AP:39,IC:0.0424},{AP:41,IC:0.04168},{AP:42,IC:0.04134},{AP:44,IC:0.04073},{AP:45,IC:0.04045},{AP:47,IC:0.03993},{AP:48,IC:0.0397},{AP:51,IC:0.03911},{AP:54,IC:0.03867},{AP:57,IC:0.0385}
  ],
  Tri : [
    {AP:0,IC:0.06},{AP:3,IC:0.05739},{AP:6,IC:0.05492},{AP:9,IC:0.05257},{AP:10,IC:0.05182},{AP:12,IC:0.05036},{AP:13,IC:0.04965},{AP:15,IC:0.04828},{AP:16,IC:0.04761},{AP:18,IC:0.04632},{AP:19,IC:0.0457},{AP:20,IC:0.0451},{AP:21,IC:0.0445},{AP:22,IC:0.04393},{AP:23,IC:0.04336},{AP:24,IC:0.04282},{AP:25,IC:0.04228},{AP:26,IC:0.04176},{AP:27,IC:0.04126},{AP:28,IC:0.04077},{AP:29,IC:0.04029},{AP:30,IC:0.03983},{AP:31,IC:0.03938},{AP:32,IC:0.03895},{AP:33,IC:0.03854},{AP:34,IC:0.03813},{AP:35,IC:0.03775},{AP:36,IC:0.03737},{AP:37,IC:0.03701},{AP:38,IC:0.03667},{AP:39,IC:0.03634},{AP:41,IC:0.03572},{AP:42,IC:0.03544},{AP:44,IC:0.03491},{AP:45,IC:0.03467},{AP:47,IC:0.03423},{AP:48,IC:0.03403},{AP:51,IC:0.03352},{AP:54,IC:0.03314},{AP:57,IC:0.033}
  ],
  Machine : [
    {AP:0,IC:0.084},{AP:3,IC:0.08035},{AP:6,IC:0.07688},{AP:9,IC:0.0736},{AP:10,IC:0.07255},{AP:12,IC:0.0705},{AP:13,IC:0.06951},{AP:15,IC:0.06759},{AP:16,IC:0.06665},{AP:18,IC:0.06485},{AP:19,IC:0.06398},{AP:20,IC:0.06313},{AP:21,IC:0.06231},{AP:22,IC:0.0615},{AP:23,IC:0.06071},{AP:24,IC:0.05994},{AP:25,IC:0.05919},{AP:26,IC:0.05847},{AP:27,IC:0.05776},{AP:28,IC:0.05707},{AP:29,IC:0.05641},{AP:30,IC:0.05576},{AP:31,IC:0.05514},{AP:32,IC:0.05453},{AP:33,IC:0.05395},{AP:34,IC:0.05339},{AP:35,IC:0.05284},{AP:36,IC:0.05232},{AP:37,IC:0.05182},{AP:38,IC:0.05134},{AP:39,IC:0.05087},{AP:41,IC:0.05001},{AP:42,IC:0.04961},{AP:44,IC:0.04887},{AP:45,IC:0.04853},{AP:47,IC:0.04792},{AP:48,IC:0.04764},{AP:51,IC:0.04693},{AP:54,IC:0.0464},{AP:57,IC:0.0462}
  ],
  Blob : [
    {AP:0,IC:0.08},{AP:3,IC:0.07652},{AP:6,IC:0.07322},{AP:9,IC:0.0701},{AP:10,IC:0.06909},{AP:12,IC:0.06714},{AP:13,IC:0.0662},{AP:15,IC:0.06437},{AP:16,IC:0.06348},{AP:18,IC:0.06177},{AP:19,IC:0.06094},{AP:20,IC:0.06013},{AP:21,IC:0.05934},{AP:22,IC:0.05857},{AP:23,IC:0.05782},{AP:24,IC:0.05709},{AP:25,IC:0.05638},{AP:26,IC:0.05568},{AP:27,IC:0.05501},{AP:28,IC:0.05436},{AP:29,IC:0.05372},{AP:30,IC:0.05311},{AP:31,IC:0.05251},{AP:32,IC:0.05194},{AP:33,IC:0.05138},{AP:34,IC:0.05084},{AP:35,IC:0.05033},{AP:36,IC:0.04983},{AP:37,IC:0.04935},{AP:38,IC:0.04889},{AP:39,IC:0.04845},{AP:41,IC:0.04763},{AP:42,IC:0.04725},{AP:44,IC:0.04655},{AP:45,IC:0.04622},{AP:47,IC:0.04564},{AP:48,IC:0.04537},{AP:51,IC:0.04469},{AP:54,IC:0.04419},{AP:57,IC:0.044}
  ],
  Explo : [
    {AP:0,IC:0.117},{AP:3,IC:0.11192},{AP:6,IC:0.10709},{AP:9,IC:0.10251},{AP:10,IC:0.10105},{AP:12,IC:0.0982},{AP:13,IC:0.09682},{AP:15,IC:0.09414},{AP:16,IC:0.09284},{AP:18,IC:0.09033},{AP:19,IC:0.08912},{AP:20,IC:0.08794},{AP:21,IC:0.08678},{AP:22,IC:0.08566},{AP:23,IC:0.08456},{AP:24,IC:0.08349},{AP:25,IC:0.08245},{AP:26,IC:0.08144},{AP:27,IC:0.08045},{AP:28,IC:0.0795},{AP:29,IC:0.07857},{AP:30,IC:0.07767},{AP:31,IC:0.0768},{AP:32,IC:0.07596},{AP:33,IC:0.07514},{AP:34,IC:0.07436},{AP:35,IC:0.0736},{AP:36,IC:0.07288},{AP:37,IC:0.07218},{AP:38,IC:0.0715},{AP:39,IC:0.07086},{AP:41,IC:0.06966},{AP:42,IC:0.0691},{AP:44,IC:0.06807},{AP:45,IC:0.0676},{AP:47,IC:0.06674},{AP:48,IC:0.06635},{AP:51,IC:0.06536},{AP:54,IC:0.06463},{AP:57,IC:0.06435}
  ],
  Mini : [
    {AP:0,IC:0.1725},{AP:3,IC:0.165},{AP:6,IC:0.15788},{AP:9,IC:0.15114},{AP:10,IC:0.14898},{AP:12,IC:0.14478},{AP:13,IC:0.14274},{AP:15,IC:0.13879},{AP:16,IC:0.13688},{AP:18,IC:0.13318},{AP:19,IC:0.1314},{AP:20,IC:0.12965},{AP:21,IC:0.12795},{AP:22,IC:0.12629},{AP:23,IC:0.12467},{AP:24,IC:0.12309},{AP:25,IC:0.12156},{AP:26,IC:0.12007},{AP:27,IC:0.11862},{AP:28,IC:0.11721},{AP:29,IC:0.11584},{AP:30,IC:0.11451},{AP:31,IC:0.11323},{AP:32,IC:0.11199},{AP:33,IC:0.11079},{AP:34,IC:0.10963},{AP:35,IC:0.10852},{AP:36,IC:0.10744},{AP:37,IC:0.10641},{AP:38,IC:0.10542},{AP:39,IC:0.10447},{AP:41,IC:0.10271},{AP:42,IC:0.10188},{AP:44,IC:0.10036},{AP:45,IC:0.09967},{AP:47,IC:0.0984},{AP:48,IC:0.09783},{AP:51,IC:0.09637},{AP:54,IC:0.09529},{AP:57,IC:0.09488}
  ],
  Heavy : [
    {AP:0,IC:0.225},{AP:3,IC:0.21522},{AP:6,IC:0.20594},{AP:9,IC:0.19714},{AP:10,IC:0.19432},{AP:12,IC:0.18884},{AP:13,IC:0.18618},{AP:15,IC:0.18103},{AP:16,IC:0.17854},{AP:18,IC:0.17371},{AP:19,IC:0.17139},{AP:20,IC:0.16911},{AP:21,IC:0.16689},{AP:22,IC:0.16472},{AP:23,IC:0.16261},{AP:24,IC:0.16056},{AP:25,IC:0.15855},{AP:26,IC:0.15661},{AP:27,IC:0.15472},{AP:28,IC:0.15288},{AP:29,IC:0.15109},{AP:30,IC:0.14937},{AP:31,IC:0.14769},{AP:32,IC:0.14607},{AP:33,IC:0.14451},{AP:34,IC:0.143},{AP:35,IC:0.14154},{AP:36,IC:0.14014},{AP:37,IC:0.1388},{AP:38,IC:0.13751},{AP:39,IC:0.13627},{AP:41,IC:0.13396},{AP:42,IC:0.13289},{AP:44,IC:0.13091},{AP:45,IC:0.13},{AP:47,IC:0.12835},{AP:48,IC:0.12761},{AP:51,IC:0.1257},{AP:54,IC:0.12429},{AP:57,IC:0.12375}
  ],
  Hydra : [
    {AP:0,IC:0.35},{AP:3,IC:0.31875},{AP:6,IC:0.29888},{AP:9,IC:0.28239},{AP:10,IC:0.27741},{AP:12,IC:0.26806},{AP:13,IC:0.26367},{AP:15,IC:0.25536},{AP:16,IC:0.25144},{AP:18,IC:0.24399},{AP:19,IC:0.24046},{AP:20,IC:0.23706},{AP:21,IC:0.23377},{AP:22,IC:0.23059},{AP:23,IC:0.22752},{AP:24,IC:0.22456},{AP:25,IC:0.2217},{AP:26,IC:0.21894},{AP:27,IC:0.21628},{AP:28,IC:0.21371},{AP:29,IC:0.21123},{AP:30,IC:0.20885},{AP:31,IC:0.20656},{AP:32,IC:0.20435},{AP:33,IC:0.20223},{AP:34,IC:0.20019},{AP:35,IC:0.19823},{AP:36,IC:0.19636},{AP:37,IC:0.19457},{AP:38,IC:0.19286},{AP:39,IC:0.19122},{AP:41,IC:0.18819},{AP:42,IC:0.18679},{AP:44,IC:0.18421},{AP:45,IC:0.18303},{AP:47,IC:0.18089},{AP:48,IC:0.17994},{AP:51,IC:0.17749},{AP:54,IC:0.17569},{AP:57,IC:0.175}
  ],
  BallPoint : [
    {AP:0,IC:0.25},{AP:3,IC:0.23914},{AP:6,IC:0.22882},{AP:9,IC:0.21905},{AP:10,IC:0.21591},{AP:12,IC:0.20982},{AP:13,IC:0.20687},{AP:15,IC:0.20115},{AP:16,IC:0.19838},{AP:18,IC:0.19302},{AP:19,IC:0.19043},{AP:20,IC:0.1879},{AP:21,IC:0.18543},{AP:22,IC:0.18303},{AP:23,IC:0.18068},{AP:24,IC:0.1784},{AP:25,IC:0.17617},{AP:26,IC:0.17401},{AP:27,IC:0.17191},{AP:28,IC:0.16986},{AP:29,IC:0.16788},{AP:30,IC:0.16596},{AP:31,IC:0.1641},{AP:32,IC:0.1623},{AP:33,IC:0.16057},{AP:34,IC:0.15889},{AP:35,IC:0.15727},{AP:36,IC:0.15572},{AP:37,IC:0.15422},{AP:38,IC:0.15279},{AP:39,IC:0.15141},{AP:41,IC:0.14885},{AP:42,IC:0.14766},{AP:44,IC:0.14546},{AP:45,IC:0.14445},{AP:47,IC:0.14261},{AP:48,IC:0.14178},{AP:51,IC:0.13967},{AP:54,IC:0.1381},{AP:57,IC:0.1375}
  ],
  Nautilus : [
    {AP:0,IC:0.15},{AP:3,IC:0.14348},{AP:6,IC:0.13729},{AP:9,IC:0.13143},{AP:10,IC:0.12955},{AP:12,IC:0.12589},{AP:13,IC:0.12412},{AP:15,IC:0.12069},{AP:16,IC:0.11903},{AP:18,IC:0.11581},{AP:19,IC:0.11426},{AP:20,IC:0.11274},{AP:21,IC:0.11126},{AP:22,IC:0.10982},{AP:23,IC:0.10841},{AP:24,IC:0.10704},{AP:25,IC:0.1057},{AP:26,IC:0.10441},{AP:27,IC:0.10314},{AP:28,IC:0.10192},{AP:29,IC:0.10073},{AP:30,IC:0.09958},{AP:31,IC:0.09846},{AP:32,IC:0.09738},{AP:33,IC:0.09634},{AP:34,IC:0.09533},{AP:35,IC:0.09436},{AP:36,IC:0.09343},{AP:37,IC:0.09253},{AP:38,IC:0.09167},{AP:39,IC:0.09085},{AP:41,IC:0.08931},{AP:42,IC:0.08859},{AP:44,IC:0.08727},{AP:45,IC:0.08667},{AP:47,IC:0.08557},{AP:48,IC:0.08507},{AP:51,IC:0.0838},{AP:54,IC:0.08286},{AP:57,IC:0.0825}
  ],
  Dapples : [
    {AP:0,IC:0.00663},{AP:3,IC:0.00634},{AP:6,IC:0.00607},{AP:9,IC:0.00581},{AP:10,IC:0.00573},{AP:12,IC:0.00556},{AP:13,IC:0.00549},{AP:15,IC:0.00533},{AP:16,IC:0.00526},{AP:18,IC:0.00512},{AP:19,IC:0.00505},{AP:20,IC:0.00498},{AP:21,IC:0.00492},{AP:22,IC:0.00485},{AP:23,IC:0.00479},{AP:24,IC:0.00473},{AP:25,IC:0.00467},{AP:26,IC:0.00461},{AP:27,IC:0.00456},{AP:28,IC:0.0045},{AP:29,IC:0.00445},{AP:30,IC:0.0044},{AP:31,IC:0.00435},{AP:32,IC:0.0043},{AP:33,IC:0.00426},{AP:34,IC:0.00421},{AP:35,IC:0.00417},{AP:36,IC:0.00413},{AP:37,IC:0.00409},{AP:38,IC:0.00405},{AP:39,IC:0.00402},{AP:41,IC:0.00395},{AP:42,IC:0.00392},{AP:44,IC:0.00386},{AP:45,IC:0.00383},{AP:47,IC:0.00378},{AP:48,IC:0.00376},{AP:51,IC:0.0037},{AP:54,IC:0.00366},{AP:57,IC:0.00365}
  ],
  Dualies : [
    {AP:0,IC:0.0072},{AP:3,IC:0.00689},{AP:6,IC:0.00659},{AP:9,IC:0.00631},{AP:10,IC:0.00622},{AP:12,IC:0.00604},{AP:13,IC:0.00596},{AP:15,IC:0.00579},{AP:16,IC:0.00571},{AP:18,IC:0.00556},{AP:19,IC:0.00548},{AP:20,IC:0.00541},{AP:21,IC:0.00534},{AP:22,IC:0.00527},{AP:23,IC:0.0052},{AP:24,IC:0.00514},{AP:25,IC:0.00507},{AP:26,IC:0.00501},{AP:27,IC:0.00495},{AP:28,IC:0.00489},{AP:29,IC:0.00484},{AP:30,IC:0.00478},{AP:31,IC:0.00473},{AP:32,IC:0.00467},{AP:33,IC:0.00462},{AP:34,IC:0.00458},{AP:35,IC:0.00453},{AP:36,IC:0.00448},{AP:37,IC:0.00444},{AP:38,IC:0.0044},{AP:39,IC:0.00436},{AP:41,IC:0.00429},{AP:42,IC:0.00425},{AP:44,IC:0.00419},{AP:45,IC:0.00416},{AP:47,IC:0.00411},{AP:48,IC:0.00408},{AP:51,IC:0.00402},{AP:54,IC:0.00398},{AP:57,IC:0.00396}
  ],
  Glooga : [
    {AP:0,IC:0.014},{AP:3,IC:0.01339},{AP:6,IC:0.01281},{AP:9,IC:0.01227},{AP:10,IC:0.01209},{AP:12,IC:0.01175},{AP:13,IC:0.01158},{AP:15,IC:0.01126},{AP:16,IC:0.01111},{AP:18,IC:0.01081},{AP:19,IC:0.01066},{AP:20,IC:0.01052},{AP:21,IC:0.01038},{AP:22,IC:0.01025},{AP:23,IC:0.01012},{AP:24,IC:0.00999},{AP:25,IC:0.00987},{AP:26,IC:0.00974},{AP:27,IC:0.00963},{AP:28,IC:0.00951},{AP:29,IC:0.0094},{AP:30,IC:0.00929},{AP:31,IC:0.00919},{AP:32,IC:0.00909},{AP:33,IC:0.00899},{AP:34,IC:0.0089},{AP:35,IC:0.00881},{AP:36,IC:0.00872},{AP:37,IC:0.00864},{AP:38,IC:0.00856},{AP:39,IC:0.00848},{AP:41,IC:0.00834},{AP:42,IC:0.00827},{AP:44,IC:0.00815},{AP:45,IC:0.00809},{AP:47,IC:0.00799},{AP:48,IC:0.00794},{AP:51,IC:0.00782},{AP:54,IC:0.00773},{AP:57,IC:0.0077}
  ],
  DualieSquelcher : [
    {AP:0,IC:0.012},{AP:3,IC:0.01148},{AP:6,IC:0.01098},{AP:9,IC:0.01051},{AP:10,IC:0.01036},{AP:12,IC:0.01007},{AP:13,IC:0.00993},{AP:15,IC:0.00966},{AP:16,IC:0.00952},{AP:18,IC:0.00926},{AP:19,IC:0.00914},{AP:20,IC:0.00902},{AP:21,IC:0.0089},{AP:22,IC:0.00879},{AP:23,IC:0.00867},{AP:24,IC:0.00856},{AP:25,IC:0.00846},{AP:26,IC:0.00835},{AP:27,IC:0.00825},{AP:28,IC:0.00815},{AP:29,IC:0.00806},{AP:30,IC:0.00797},{AP:31,IC:0.00788},{AP:32,IC:0.00779},{AP:33,IC:0.00771},{AP:34,IC:0.00763},{AP:35,IC:0.00755},{AP:36,IC:0.00747},{AP:37,IC:0.0074},{AP:38,IC:0.00733},{AP:39,IC:0.00727},{AP:41,IC:0.00714},{AP:42,IC:0.00709},{AP:44,IC:0.00698},{AP:45,IC:0.00693},{AP:47,IC:0.00685},{AP:48,IC:0.00681},{AP:51,IC:0.0067},{AP:54,IC:0.00663},{AP:57,IC:0.0066 }
  ],
  SplatBrella : [
    {AP:0,IC:0.06325},{AP:3,IC:0.0605},{AP:6,IC:0.05789},{AP:9,IC:0.05542},{AP:10,IC:0.},{AP:12,IC:0.05309},{AP:13,IC:0.05234},{AP:15,IC:0.05089},{AP:16,IC:0.05019},{AP:18,IC:0.04883},{AP:19,IC:0.04818},{AP:20,IC:0.04754},{AP:21,IC:0.04691},{AP:22,IC:0.04631},{AP:23,IC:0.04571},{AP:24,IC:0.04513},{AP:25,IC:0.04457},{AP:26,IC:0.04402},{AP:27,IC:0.04349},{AP:28,IC:0.04298},{AP:29,IC:0.04247},{AP:30,IC:0.04199},{AP:31,IC:0.04152},{AP:32,IC:0.04106},{AP:33,IC:0.04062},{AP:34,IC:0.0402},{AP:35,IC:0.03979},{AP:36,IC:0.0394},{AP:37,IC:0.03902},{AP:38,IC:0.03865},{AP:39,IC:0.03831},{AP:41,IC:0.03766},{AP:42,IC:0.03736},{AP:44,IC:0.0368},{AP:45,IC:0.03655},{AP:47,IC:0.03608},{AP:48,IC:0.03587},{AP:51,IC:0.03534},{AP:54,IC:0.03494},{AP:57,IC:0.03479}
  ],
  Tetras : [
    {AP:0,IC:0.008},{AP:3,IC:0.00765},{AP:6,IC:0.00732},{AP:9,IC:0.00701},{AP:10,IC:0.00691},{AP:12,IC:0.00671},{AP:13,IC:0.00662},{AP:15,IC:0.00644},{AP:16,IC:0.00635},{AP:18,IC:0.00618},{AP:19,IC:0.00609},{AP:20,IC:0.00601},{AP:21,IC:0.00593},{AP:22,IC:0.00586},{AP:23,IC:0.00578},{AP:24,IC:0.00571},{AP:25,IC:0.00564},{AP:26,IC:0.00557},{AP:27,IC:0.0055},{AP:28,IC:0.00544},{AP:29,IC:0.00537},{AP:30,IC:0.00531},{AP:31,IC:0.00525},{AP:32,IC:0.00519},{AP:33,IC:0.00514},{AP:34,IC:0.00508},{AP:35,IC:0.00503},{AP:36,IC:0.00498},{AP:37,IC:0.00494},{AP:38,IC:0.00489},{AP:39,IC:0.00485},{AP:41,IC:0.00476},{AP:42,IC:0.00473},{AP:44,IC:0.00465},{AP:45,IC:0.00462},{AP:47,IC:0.00456},{AP:48,IC:0.00454},{AP:51,IC:0.00447},{AP:54,IC:0.00442},{AP:57,IC:0.0044}
  ],
  Tent : [
    {AP:0,IC:0.11},{AP:3,IC:0.10018},{AP:6,IC:0.09393},{AP:9,IC:0.08875},{AP:10,IC:0.08719},{AP:12,IC:0.08425},{AP:13,IC:0.08287},{AP:15,IC:0.08026},{AP:16,IC:0.07902},{AP:18,IC:0.07668},{AP:19,IC:0.07557},{AP:20,IC:0.0745},{AP:21,IC:0.07347},{AP:22,IC:0.07247},{AP:23,IC:0.07151},{AP:24,IC:0.07058},{AP:25,IC:0.06968},{AP:26,IC:0.06881},{AP:27,IC:0.06797},{AP:28,IC:0.06717},{AP:29,IC:0.06639},{AP:30,IC:0.06564},{AP:31,IC:0.06492},{AP:32,IC:0.06422},{AP:33,IC:0.06356},{AP:34,IC:0.06292},{AP:35,IC:0.0623},{AP:36,IC:0.06171},{AP:37,IC:0.06115},{AP:38,IC:0.06061},{AP:39,IC:0.0601},{AP:41,IC:0.05915},{AP:42,IC:0.0587},{AP:44,IC:0.05789},{AP:45,IC:0.05752},{AP:47,IC:0.05685},{AP:48,IC:0.05655},{AP:51,IC:0.05578},{AP:54,IC:0.05522},{AP:57,IC:0.055}
  ],
  Undercover : [
    {AP:0,IC:0.04},{AP:3,IC:0.03826},{AP:6,IC:0.03661},{AP:9,IC:0.03505},{AP:10,IC:0.03455},{AP:12,IC:0.03357},{AP:13,IC:0.0331},{AP:15,IC:0.03218},{AP:16,IC:0.03174},{AP:18,IC:0.03088},{AP:19,IC:0.03047},{AP:20,IC:0.03006},{AP:21,IC:0.02967},{AP:22,IC:0.02928},{AP:23,IC:0.02891},{AP:24,IC:0.02854},{AP:25,IC:0.02819},{AP:26,IC:0.02784},{AP:27,IC:0.0275},{AP:28,IC:0.02718},{AP:29,IC:0.02686},{AP:30,IC:0.02655},{AP:31,IC:0.02626},{AP:32,IC:0.02597},{AP:33,IC:0.02569},{AP:34,IC:0.02542},{AP:35,IC:0.02516},{AP:36,IC:0.02491},{AP:37,IC:0.02468},{AP:38,IC:0.02445},{AP:39,IC:0.02423},{AP:41,IC:0.02382},{AP:42,IC:0.02363},{AP:44,IC:0.02327},{AP:45,IC:0.02311},{AP:47,IC:0.02282},{AP:48,IC:0.02269},{AP:51,IC:0.02235},{AP:54,IC:0.0221},{AP:57,IC:0.022}
  ],
}