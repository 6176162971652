export const headAbilityData = 
  [
  {name:"キャディ サンバイザー", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"スカッシュバンド", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"テッカサイクルキャップ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック",]},
  {name:"バスケバンド", abilityArray:["スタートダッシュ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ラストスパート","逆境強化","カムバック"]},
  {name:"アナアキマスク", abilityArray:["スタートダッシュ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ラストスパート","逆境強化","カムバック"]},
  {name:"アナアキアンピンベレー", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"アナアキバスクベレー", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"テニスバンド", abilityArray:["カムバック","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","逆境強化"]},
  {name:"サッカーバンド", abilityArray:["逆境強化","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"サンサンサンバイザー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ダテコンタクト", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"チャリキング帽", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"イロメガネ", abilityArray:["ラストスパート","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"エゾッコメッシュ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"バックワードキャップ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ランニングバンド", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"5パネルキャップ", abilityArray:["カムバック","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"アローバンド ブラック", abilityArray:["カムバック","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"ダブルエッグサングラス", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ラストスパート","逆境強化","カムバック"]},
  {name:"アローバンド ホワイト", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"ツヤケシMTBメット", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ロゴヅクシMTBメット", abilityArray:["逆境強化","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"キングオブマスク", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"キングタコスターマスク", abilityArray:["逆境強化","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"キングフリップメッシュ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"2ラインメッシュ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"エイズリーバンダナ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"マルベッコー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"ウールウーニーズクラシック", abilityArray:["カムバック","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","逆境強化"]},
  {name:"ヒッコリーワークキャップ", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"ロングビルキャップ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"キャンプキャップ", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"キャンプハット", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ショートビーニー", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ヤマギリビーニー", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","スタートダッシュ","逆境強化","カムバック"]},
  {name:"カイガラスゲ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"スゲ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"クロブチ レトロ", abilityArray:["復活時間短縮","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ボーダービーニー", abilityArray:["スタートダッシュ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","逆境強化","カムバック"]},
  {name:"セルブロウ", abilityArray:["スペシャル性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ラストスパート","逆境強化","カムバック"]},
  {name:"ハウスタグデニムCAP", abilityArray:["スペシャル増加量アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ラストスパート","逆境強化","カムバック"]},
  {name:"ボンボンニット", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"センニュークロブチ", abilityArray:["イカダッシュ速度アップ"]},
  {name:"センニューボンボン", abilityArray:["インク効率アップ(サブ)"]},
  {name:"スミガードゴーグル", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート"]},
  {name:"イカバケット", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"イカバッテンガード", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ヘッドバンド ホワイト", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"ペインターマスク", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"アンコウラバーマスク", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"キョンキョンぼう", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"こうしえんバンダナ", abilityArray:["イカダッシュ速度アップ"]},
  {name:"コズミックアンテナ", abilityArray:["カムバック","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"ニューイヤーグラスDX", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"バーニングブロイラー", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","爆風ダメージ軽減・改","メイン性能アップ","メイン性能アップ"]},
  {name:"ハッピーチンアナゴハット", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"フォーリマスク", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"オーロラヘッドホン", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"スタジオヘッドホン", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"トレジャーメット", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"パイロットゴーグル", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ロブスターブーニー", abilityArray:["ラストスパート","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"イカスカルマスク", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"イヤーマフ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"オクトメットプライズデコ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ステカセヘッドホン", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"センニューゴーグル", abilityArray:["相手インク影響軽減"]},
  {name:"センニューヘッドホン", abilityArray:["インク効率アップ(メイン)"]},
  {name:"タコマスク", abilityArray:["逆境強化","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","カムバック"]},
  {name:"デカメットアイシールド", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","相手インク影響軽減","爆風ダメージ軽減・改","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"ナイトビジョン", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ノーズガードモトクロス", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"フェイスゴーグル", abilityArray:["カムバック","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"モンゴウベレー", abilityArray:["スタートダッシュ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ラストスパート","逆境強化","カムバック"]},
  {name:"イカベーダーキャップ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"イカンカン", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ウーニーズBBキャップ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ウインターボンボン", abilityArray:["逆境強化","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","ラストスパート"]},
  {name:"ビバレッジキャップ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"オシノビニット", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"サイクルメット", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"スケボーメット", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"クラゲーダーキャップ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","カムバック"]},
  {name:"チドリキャップ", abilityArray:["スタートダッシュ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ラストスパート","逆境強化","カムバック"]},
  {name:"ドゥーラグキャップガサネ", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"バイザーメット", abilityArray:["ラストスパート","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"カモメッシュ", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"クロヤキフグバンダナ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ジェットキャップ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"フグベルハット", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"マスクドホッコリー", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ヤキフグ サンバイザー", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"リブニット", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"フグベルベルハット", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","スタートダッシュ","ラストスパート","逆境強化"]},
  {name:"サファリハット", abilityArray:["インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ヤコメッシュ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ヤコバイザー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"タレサン18K", abilityArray:["ラストスパート","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","逆境強化","カムバック"]},
  {name:"マルサンSV925", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","スタートダッシュ","ラストスパート","逆境強化","カムバック"]},
  {name:"ダストブロッカー 2.5", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"ヘッドライトヘルム", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"マリリンハットウィズピンズ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"レジェンドのぼうし", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]}
]