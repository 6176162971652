export const clothesAbilityData = [
  {name:"アイロニックレイヤード", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"アイロニックロング", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","相手インク影響軽減","爆風ダメージ軽減・改","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ウーニーズBBシャツ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"デカロTキウイ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"デカロTゴールデン", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"デカロゴT シロ", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"デカロゴT ノーコン", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"トビウォーズHKユニ", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バスケジャージ アウェイ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","相手インク影響軽減","爆風ダメージ軽減・改","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"スクールジャージー", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"テッカサイクルシャツ", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バスケジャージ ホーム", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"FCジャージー", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキニット アオソデ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキニット アカソデ", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキニット キソデ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキロンT ドライブ", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"ピーターパンクシャツ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキT ポルポピック", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキオンブレネル", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アナアキロンT イボルブ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"パラシューター ザクロ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"パラシューター プルーン", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ピーターパンクタイ ブラッド", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"ロッケンブレイクジャケット", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカノメT ブラック", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカノメT ライトブルー", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"かくれパイレーツ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"アロメスローガンT", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アロメテックT ブルー", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アロメテックT レッド", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ロブスターズユニ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ"]},
  {name:"チャリキングジャージ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"エゾッコラグラン", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"エゾッコロンTラディッシュ", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"リールロールスウェット", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ウラスカジャン", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"エゾッココーチ アカネ", abilityArray:["リベンジ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"エゾッココーチ ウコン", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"エゾッココーチ モエギ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"エゾッコパーカー アズキ", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"エゾッコパーカー ウグイス", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","復活ペナルティアップ"]},
  {name:"エゾッコロンTキャロット", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ギンガムチェック アカ", abilityArray:["インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ギンガムチェック ミドリ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"バニーポップ ブラック", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ミックスシャツ グレー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ロゴマシマシアロハ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカスカジャン", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ウラタコスカジャン", abilityArray:["メイン性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"スタジャンロゴマシ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"トリスカベッチン", abilityArray:["ヒト移動速度アップ","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","相手インク影響軽減","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"キングオクトTミドリベロア", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"キングタンク スラッシュ", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"キングタンク ホワイト", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"キングパーカー グレープ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"キングHKシャツ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"キングオクトHKシャツ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"キングオクトTブラックベロア", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","イカニンジャ","復活ペナルティアップ"]},
  {name:"エンペーサートレーナー", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"キングベンチNV", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカゴッチンベスト", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","相手インク影響軽減","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカスタンシャツ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカノボリベスト", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"クラーゲス528", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"サニーオレンジT", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク回復力アップ","スペシャル減少量ダウン","スペシャル性能アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"レイニーブルーT", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"タコボウラーシャツ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"チリメンタコアロハ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ミズシュラッグT", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","スペシャル増加量アップ","スペシャル減少量ダウン","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ミスターシュラッグT", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ボーダーモスグリーン", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤマビコT アイボリー", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤマビコT ブルー", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤマビコボーダー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"フィッシャーズベスト オジ", abilityArray:["復活時間短縮","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マウンテンベリー", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"フォレストダウン", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","サーマルインク"]},
  {name:"マウンテンオリーブ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マウンテンダウン", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"マウンテンチリペッパー", abilityArray:["相手インク影響軽減","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マウンテンノリタマゴ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マウンテンフローズン", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マッチャ ライトダウン", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"カレッジスウェット グレー", abilityArray:["イカダッシュ速度アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"カレッジスウェット ネイビー", abilityArray:["相手インク影響軽減","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"カレッジラグラン", abilityArray:["リベンジ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"さくらエビポロ", abilityArray:["イカニンジャ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"シャンブレーシャツ", abilityArray:["サブ性能アップ","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"パイレーツボーダー", abilityArray:["スペシャル性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ベイビークラゲシャツ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ボーダーネイビー", abilityArray:["インク回復力アップ","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マリンボーダー", abilityArray:["ヒト移動速度アップ","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"よもぎポロ", abilityArray:["メイン性能アップ","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"レタード オレンジ", abilityArray:["スペシャル増加量アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"シャツノゾキピンク", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"シャツノゾキブルー", abilityArray:["スペシャル性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"タイツキベイビークラゲ", abilityArray:["メイン性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ブロックストライプシャツ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ボーダーホワイト", abilityArray:["復活時間短縮","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ゆとりボーダー ライム", abilityArray:["相手インク影響軽減","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"レタード グリーン", abilityArray:["インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"シロシャツ", abilityArray:["インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"タイシャツ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ハウスタグホエールニット", abilityArray:["ヒト移動速度アップ","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"スウェットT ゴブ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"スミカケシャツ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"スミヌキシャツ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"フロントジップベスト", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"クンフージャージ", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","リベンジ","サーマルインク"]},
  {name:"クンフーパンジャージ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ケンサキコーチ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ショートニットカサネ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"タタキBBBシャツ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"ドロップスウェット ネガ", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"ドロップスウェット ポジ", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"プルオーバーコート", abilityArray:["サーマルインク","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ"]},
  {name:"ボンバージャケット ネガ", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ボンバージャケット ポジ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカバッテン マスタード", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカバッテンロング", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカブラック", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカホワイト", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"カモガサネ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バトロング ホワイト", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"フレンドT", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","サブ性能アップ","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ"]},
  {name:"マスタードガサネ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"レイヤード ブラック", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"レイヤード ホワイト", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"わかばイカT", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"イカイエローVガサネ", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカグリーンV コラボ", abilityArray:["スーパージャンプ時間短縮"]},
  {name:"イカブラックV", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"イカホワイトV", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカリスウェット", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカレッドV コラボ", abilityArray:["復活時間短縮"]},
  {name:"ガチガサネ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ガチブラック", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ガチホワイト", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"リーグT", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"SWCロゴT", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","サブ性能アップ","爆風ダメージ軽減・改","爆風ダメージ軽減・改","メイン性能アップ","復活ペナルティアップ"]},
  {name:"おうえんパーカー(タコver.)", abilityArray:["メイン性能アップ"]},
  {name:"フェスT レプリカ", abilityArray:["追加ギアパワー倍化"]},
  {name:"レトロジャッジ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","リベンジ","サーマルインク"]},
  {name:"おどるイカアロハ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"グリーンT", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"F-010", abilityArray:["リベンジ","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"F-190", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"FA-11ウラ カスタム", abilityArray:["爆風ダメージ軽減・改"]},
  {name:"FA-11オモテ カスタム", abilityArray:["爆風ダメージ軽減・改"]},
  {name:"イカセーラー ホワイト", abilityArray:["インク効率アップ(メイン)"]},
  {name:"F-3 ペイントカスタム", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"F-3 ホワイトレザー", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"FA-01ウラ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"FA-01オモテ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカセーラー ブルー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アイスグラデT", abilityArray:["イカニンジャ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"グレープT", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"チドリメロンT", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"パールドットT", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ホットグラデT", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ミントT", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ホタパーカー グレー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ホタパーカー ブラック", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ホタプラントパーカー", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカジャマイカ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"レイヤードットシャツ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","サブ性能アップ","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ω-3T", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ソウショクT", abilityArray:["イカニンジャ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ニクショクT", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"マルフグT", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤキフグ8bit グレー", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","相手インク影響軽減","爆風ダメージ軽減・改","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤキフグ8bit ブラック", abilityArray:["爆風ダメージ軽減・改","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤキフグ8bit ホワイト", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アーバンベスト イエロー", abilityArray:["リベンジ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"ジップアップ グリーン", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ホッコリー ネイビー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤキフグ16bit カーキ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤキフグ16bit ブルー", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"アーバンベスト ナイト", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ジップアップ カモ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ミスターベースボール", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"アオサドーレ", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"カモフラパープル", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"チョコガサネ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"トリコロールラガー", abilityArray:["復活時間短縮"]},
  {name:"ベクトルパターン グレー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"ベクトルパターン レッド", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ベクトルラインガサネ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤコタイダイ ギャラクシー", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"ヤコタイダイ レインボー", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"FCカラスミ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"オレンジボーダーラガー", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル減少量ダウン","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤコラグランクレイジー", abilityArray:["イカニンジャ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤコナイロン ヴィンテージ", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","復活ペナルティアップ"]},
  {name:"ヤコナイロンレトロ", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヤコピステ", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"オータムネル", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ガンガゼロックT クロ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ガンガゼロックT シロ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"ハラグロラグラン", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ハラシロラグラン", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バンドT ABXY", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バンドT HIGHTIDEERA", abilityArray:["サーマルインク","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","イカニンジャ","復活ペナルティアップ"]},
  {name:"バンドT SQUIDSQUAD", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"バンドT WETFLOOR", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ロッケンベルグT ブラック", abilityArray:["復活ペナルティアップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"マルエリシャツ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ロッケンベルグT ホワイト", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク"]},
  {name:"イカライダーBLACK", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"イカライダーWHITE", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","イカニンジャ","リベンジ","サーマルインク","復活ペナルティアップ"]},
  {name:"ヴィンテージチェック", abilityArray:["リベンジ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","イカニンジャ","サーマルインク","復活ペナルティアップ"]},
  {name:"わかばタコT", abilityArray:["インク効率アップ(サブ)"]},
  {name:"タコロンT 赤", abilityArray:["爆風ダメージ軽減・改"]},
  {name:"タコロンT 黒", abilityArray:["イカダッシュ速度アップ"]},
  {name:"タコT", abilityArray:["リベンジ"]},
  {name:"BBシャツ プレミアム", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"アカシックコート", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"アゲアゲ・10・プラーXXL", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"アンカーベスト", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"イカテンくんポロ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"ジムパーソンアーマー", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"デベロッパーズワークウエア", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"フューエルブルゾン", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"フロムキタグニ87", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"マクレGT", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"レコヤルックEP", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]}
]