export const shoesAbilityData = [
  {name:"シーホース ホワイト", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"シーホースHi ゾンビ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","サブ性能アップ","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"シーホースHi パープル", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"シーホースHi レッド", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"デカロニックミント", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ",""]},
  {name:"デカロノーレース ブルー", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","","","",""]},
  {name:"シーホース イエロー", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","ステルスジャンプ","受け身術","","","","",""]},
  {name:"シーホース ブラックレザー", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","","","",""]},
  {name:"デカロニックブラック", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","","",""]},
  {name:"デカロノーレース ホワイト", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"シーホースHi ゴールド", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"アナアキスパイダー6ホール", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"アナアキラバー ハバネロ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"アナアキラバー フェイク", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"アロメッシュイエロー", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"イロマキ750 イエロー", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"イロマキ750 オレンジ", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","","","","","",""]},
  {name:"イロマキ750 グリーン", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"イロマキ750 パープル", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"イロマキ750 レッド", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ウミウシイエロー", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ウミウシブルー", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","","","",""]},
  {name:"カナリアビーンズ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"シアンビーンズ", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","",""]},
  {name:"スミレビーンズ", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","受け身術","","",""]},
  {name:"ピンクビーンズ", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"ブラックビーンズ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"アロメッシュバーミリオン", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ウミウシパープル", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"アイスダウンブーツ", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","対物攻撃力アップ","受け身術","受け身術","","",""]},
  {name:"ウミウシタウンHi アンバー", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","","",""]},
  {name:"ウミウシタウンHi グレー", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ウミウシレッド", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル性能アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ノリマキ750 ブラック", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ミルキーダウンブーツ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"BBサンダル", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","受け身術",""]},
  {name:"グリッチョ オレンジ", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"グリッチョ ブルー", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"ノーリーチャッカ グレー", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ノーリーチャッカ ロビンズ", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"ノーリーチャッカ トライブ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"グリッチョ グリーン 限定版", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"エギング5 ハニオレ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"エンペーサーAg", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","","",""]},
  {name:"エンペーサーCaO", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","受け身術","","","","",""]},
  {name:"エギング4 アオクロ", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"エギング4 アカクロ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"エギング4 キイコン", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"エギング5 クロアオ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"エギング5 シロアカ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"エギング5 プレミアム", abilityArray:["ステルスジャンプ"]},
  {name:"エンペーサーAu", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"エンペリアルNV", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","","","",""]},
  {name:"オイスタークロッグ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"キャンバス クマノミ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"キャンバス バナナ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","受け身術","","","","",""]},
  {name:"キャンバス ホワイト", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"キャンバスHi トリュフ", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"キャンバスHi マッシュルーム", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","",""]},
  {name:"キャンバスHi モロヘイヤ", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"スリッポン ブルー", abilityArray:["サブ性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"スリッポン レッド", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"キャンバスHi トマト", abilityArray:["相手インク影響軽減","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"スリッポン チドリ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"スリッポン ポルカ", abilityArray:["受け身術","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","","",""]},
  {name:"チョコクロッグ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"フジツボペタサン", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ボルダーシューズ ハザクラ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"レイニーアセロラ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"レイニーシャボン", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","ステルスジャンプ","対物攻撃力アップ","","","","",""]},
  {name:"デルタストラップ ネオン", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"デルタストラップ ルミナス", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","ステルスジャンプ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"トレッキングカスタム", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ボルダーシューズ タイノエ", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"レイニーモスグリーン", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"デルタストラップ スノー", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"トレッキングプロ", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"トレッキングライト", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"シャークモカシン", abilityArray:["サブ性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ヒモヒモグリーン", abilityArray:["メイン性能アップ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","対物攻撃力アップ","受け身術","","",""]},
  {name:"ベリベリレッド", abilityArray:["相手インク影響軽減","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"ジョーズモカシン", abilityArray:["インク回復力アップ","インク効率アップ(サブ)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"チャバハンティングブーツ", abilityArray:["復活時間短縮","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ハンティングブーツ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ピラニアモカシン", abilityArray:["ステルスジャンプ","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術",""]},
  {name:"ベリベリホワイト", abilityArray:["インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"アローズ タタキベッチュー", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","ステルスジャンプ","対物攻撃力アップ","","","","",""]},
  {name:"ストリップ タタキベッチュー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル減少量ダウン","スペシャル性能アップ","スペシャル性能アップ","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"スパイカ タタキベッチュー", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"ヤキフグシャワサン アカ", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","受け身術","","","",""]},
  {name:"ヤキフグシャワサン キ", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"アスレチックアローズ", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","受け身術","",""]},
  {name:"オレンジアローズ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"オルカHi", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"オルカHiウーブン", abilityArray:["インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","イカダッシュ速度アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","",""]},
  {name:"オルカHiサンセット", abilityArray:["受け身術","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ",""]},
  {name:"オルカHiパッション", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"クレイジーアローズ", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","",""]},
  {name:"パワーストリップ アオアシ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"パワーストリップ アカアシ", abilityArray:["ヒト移動速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ホワイトアローズ", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","受け身術","","",""]},
  {name:"ユデスパイカ", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"レアスパイカ", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","ヒト移動速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"イカスミチップ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","","",""]},
  {name:"ラバーソール ホワイト", abilityArray:["イカダッシュ速度アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"グレーチップ イエローソール", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","",""]},
  {name:"シンリョクレザーブーツ", abilityArray:["受け身術","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","","",""]},
  {name:"ヌバックブーツ イエロー", abilityArray:["サブ性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ネイビーチップ レッドソール", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル増加量アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"モトクロスブーツ", abilityArray:["復活時間短縮","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ラバーソール ターコイズ", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク回復力アップ","イカダッシュ速度アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ラバーソール チェリー", abilityArray:["ステルスジャンプ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","対物攻撃力アップ","受け身術","",""]},
  {name:"ロッキンイエロー", abilityArray:["スペシャル減少量ダウン","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"ロッキンホワイト", abilityArray:["スペシャル増加量アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","受け身術","","",""]},
  {name:"イカボウズキャメル", abilityArray:["スペシャル性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術",""]},
  {name:"イカボウズジェットブラック", abilityArray:["インク回復力アップ","インク効率アップ(メイン)","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","メイン性能アップ","受け身術","","","","","","",""]},
  {name:"シンカイレザーブーツ", abilityArray:["インク効率アップ(サブ)","インク効率アップ(メイン)","インク回復力アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スーパージャンプ時間短縮","サブ性能アップ","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","対物攻撃力アップ","受け身術","","","","","",""]},
  {name:"スモークチップ", abilityArray:["対物攻撃力アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","受け身術","",""]},
  {name:"センニューチップ スミソール", abilityArray:["ステルスジャンプ"]},
  {name:"ヌバックブーツ レッド", abilityArray:["スーパージャンプ時間短縮","インク効率アップ(サブ)","イカダッシュ速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","サブ性能アップ","相手インク影響軽減","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","","","",""]},
  {name:"モトクロス ソリッドブルー", abilityArray:["相手インク影響軽減","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","復活時間短縮","スーパージャンプ時間短縮","スーパージャンプ時間短縮","サブ性能アップ","爆風ダメージ軽減・改","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ロッキンチェリー", abilityArray:["爆風ダメージ軽減・改","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","メイン性能アップ","ステルスジャンプ","対物攻撃力アップ","受け身術","",""]},
  {name:"ロッキンブラック", abilityArray:["メイン性能アップ","インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","スペシャル性能アップ","復活時間短縮","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","ステルスジャンプ","対物攻撃力アップ","受け身術","","",""]},
  {name:"ゲソバタアシスト", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"チギリノヒモ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"ノンスリップタクミ", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"バンカラコロン", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]},
  {name:"マッドラバー", abilityArray:["インク効率アップ(メイン)","インク効率アップ(サブ)","インク回復力アップ","イカダッシュ速度アップ","ヒト移動速度アップ","スペシャル増加量アップ","スペシャル減少量ダウン","スペシャル性能アップ","復活時間短縮","スーパージャンプ時間短縮","サブ性能アップ","相手インク影響軽減","爆風ダメージ軽減・改","メイン性能アップ"]}
]