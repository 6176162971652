export const mainPowerUp = {
  Sploosh : [
    {AP:0,DMG:38},{AP:3,DMG:38.9},{AP:6,DMG:39.7},{AP:9,DMG:40.6},{AP:10,DMG:40.8},{AP:12,DMG:41.3},{AP:13,DMG:41.6},{AP:15,DMG:42.1},{AP:16,DMG:42.3},{AP:18,DMG:42.8},{AP:19,DMG:43},{AP:20,DMG:43.2},{AP:21,DMG:43.4},{AP:22,DMG:43.6},{AP:23,DMG:43.8},{AP:24,DMG:44},{AP:25,DMG:44.2},{AP:26,DMG:44.4},{AP:27,DMG:44.5},{AP:28,DMG:44.7},{AP:29,DMG:44.9},{AP:30,DMG:45},{AP:31,DMG:45.2},{AP:32,DMG:45.4},{AP:33,DMG:45.5},{AP:34,DMG:45.6},{AP:35,DMG:45.8},{AP:36,DMG:45.9},{AP:37,DMG:46},{AP:38,DMG:46.2},{AP:39,DMG:46.3},{AP:41,DMG:46.5},{AP:42,DMG:46.6},{AP:44,DMG:46.8},{AP:45,DMG:46.9},{AP:47,DMG:47},{AP:48,DMG:47.1},{AP:51,DMG:47.3},{AP:54,DMG:47.4},{AP:57,DMG:47.5} 
  ],
  SplatterShot : [
    {AP:0,DRJ:6,DR:12},{AP:3,DRJ:5.69426,DR:10.4713},{AP:6,DRJ:5.54818,DR:9.74088},{AP:9,DRJ:5.43593,DR:9.17966},{AP:10,DRJ:5.40318,DR:9.01589},{AP:12,DRJ:5.34296,DR:8.71478},{AP:13,DRJ:5.31512,DR:8.57561},{AP:15,DRJ:5.26333,DR:8.31665},{AP:16,DRJ:5.23917,DR:8.19585},{AP:18,DRJ:5.19391,DR:7.96957},{AP:19,DRJ:5.17269,DR:7.86346},{AP:20,DRJ:5.15233,DR:7.76166},{AP:21,DRJ:5.13279,DR:7.66397},{AP:22,DRJ:5.11403,DR:7.57015},{AP:23,DRJ:5.09601,DR:7.48005},{AP:24,DRJ:5.0787,DR:7.39348},{AP:25,DRJ:5.06206,DR:7.31032},{AP:26,DRJ:5.04608,DR:7.23041},{AP:27,DRJ:5.03073,DR:7.15365},{AP:28,DRJ:5.01598,DR:7.07992},{AP:29,DRJ:5.00183,DR:7.00913},{AP:30,DRJ:4.98824,DR:6.94119},{AP:31,DRJ:4.9752,DR:6.876},{AP:32,DRJ:4.9627,DR:6.8135},{AP:33,DRJ:4.95072,DR:6.75361},{AP:34,DRJ:4.93926,DR:6.69628},{AP:35,DRJ:4.92829,DR:6.64143},{AP:36,DRJ:4.9178,DR:6.58902},{AP:37,DRJ:4.9078,DR:6.53899},{AP:38,DRJ:4.89826,DR:6.4913},{AP:39,DRJ:4.88918,DR:6.4459},{AP:41,DRJ:4.87236,DR:6.36182},{AP:42,DRJ:4.86461,DR:6.32307},{AP:44,DRJ:4.8504,DR:6.25198},{AP:45,DRJ:4.84392,DR:6.21959},{AP:47,DRJ:4.8322,DR:6.16099},{AP:48,DRJ:4.82695,DR:6.13473},{AP:51,DRJ:4.81358,DR:6.06791},{AP:54,DRJ:4.80374,DR:6.01869},{AP:57,DRJ:4.8,DR:6}
  ],
  Aerospray : [
    {AP:0,PR:12},{AP:3,PR:12.46787},{AP:6,PR:12.70848},{AP:9,PR:12.89683},{AP:10,PR:12.95225},{AP:12,PR:13.05465},{AP:13,PR:13.10218},{AP:15,PR:13.19094},{AP:16,PR:13.23248},{AP:18,PR:13.31051},{AP:19,PR:13.3472},{AP:20,PR:13.38245},{AP:21,PR:13.41633},{AP:22,PR:13.44891},{AP:23,PR:13.48024},{AP:24,PR:13.51037},{AP:25,PR:13.53936},{AP:26,PR:13.56724},{AP:27,PR:13.59405},{AP:28,PR:13.61983},{AP:29,PR:13.6446},{AP:30,PR:13.6684},{AP:31,PR:13.69125},{AP:32,PR:13.71318},{AP:33,PR:13.7342},{AP:34,PR:13.75434},{AP:35,PR:13.77362},{AP:36,PR:13.79206},{AP:37,PR:13.80967},{AP:38,PR:13.82647},{AP:39,PR:13.84246},{AP:41,PR:13.87211},{AP:42,PR:13.88578},{AP:44,PR:13.91088},{AP:45,PR:13.92232},{AP:47,PR:13.94304},{AP:48,PR:13.95232},{AP:51,PR:13.97596},{AP:54,PR:13.99338},{AP:57,PR:14}
  ],
  Jr : [
    {AP:0,PR:12.9},{AP:3,PR:12.94884},{AP:6,PR:13.05199},{AP:9,PR:13.1897},{AP:10,PR:13.24135},{AP:12,PR:13.3514},{AP:13,PR:13.40926},{AP:15,PR:13.52947},{AP:16,PR:13.59137},{AP:18,PR:13.71782},{AP:19,PR:13.78199},{AP:20,PR:13.84658},{AP:21,PR:13.91141},{AP:22,PR:13.97634},{AP:23,PR:14.04123},{AP:24,PR:14.10593},{AP:25,PR:14.17031},{AP:26,PR:14.23426},{AP:27,PR:14.29764},{AP:28,PR:14.36035},{AP:29,PR:14.42227},{AP:30,PR:14.4833},{AP:31,PR:14.54334},{AP:32,PR:14.60228},{AP:33,PR:14.66005},{AP:34,PR:14.71655},{AP:35,PR:14.7717},{AP:36,PR:14.82542},{AP:37,PR:14.87762},{AP:38,PR:14.92825},{AP:39,PR:14.97722},{AP:41,PR:15.06996},{AP:42,PR:15.1136},{AP:44,PR:15.19514},{AP:45,PR:15.23294},{AP:47,PR:15.30236},{AP:48,PR:15.3339},{AP:51,PR:15.41538},{AP:54,PR:15.47652},{AP:57,PR:15.5}
  ],
  Splash : [
    {AP:0,DMG:28},{AP:3,DMG:28.6},{AP:6,DMG:29.3},{AP:9,DMG:29.9},{AP:10,DMG:30.1},{AP:12,DMG:30.4},{AP:13,DMG:30.6},{AP:15,DMG:31},{AP:16,DMG:31.2},{AP:18,DMG:31.5},{AP:19,DMG:31.7},{AP:20,DMG:31.8},{AP:21,DMG:32},{AP:22,DMG:32.1},{AP:23,DMG:32.3},{AP:24,DMG:32.4},{AP:25,DMG:32.5},{AP:26,DMG:32.7},{AP:27,DMG:32.8},{AP:28,DMG:32.9},{AP:29,DMG:33.1},{AP:30,DMG:33.2},{AP:31,DMG:33.3},{AP:32,DMG:33.3},{AP:33,DMG:33.3},{AP:34,DMG:33.3},{AP:35,DMG:33.3},{AP:36,DMG:33.3},{AP:37,DMG:33.3},{AP:38,DMG:33.3},{AP:39,DMG:33.3},{AP:41,DMG:33.3},{AP:42,DMG:33.3},{AP:44,DMG:33.3},{AP:45,DMG:33.3},{AP:47,DMG:33.3},{AP:48,DMG:33.3},{AP:51,DMG:33.3},{AP:54,DMG:33.3},{AP:57,DMG:33.3}  
  ],
  Gal52 : [
    {AP:0,DRJ:12,DR:6},{AP:3,DRJ:10.4713,DR:5.69426},{AP:6,DRJ:9.74088,DR:5.54818},{AP:9,DRJ:9.17966,DR:5.43593},{AP:10,DRJ:9.01589,DR:5.40318},{AP:12,DRJ:8.71478,DR:5.34296},{AP:13,DRJ:8.57561,DR:5.31512},{AP:15,DRJ:8.31665,DR:5.26333},{AP:16,DRJ:8.19585,DR:5.23917},{AP:18,DRJ:7.96957,DR:5.19391},{AP:19,DRJ:7.86346,DR:5.17269},{AP:20,DRJ:7.76166,DR:5.15233},{AP:21,DRJ:7.66397,DR:5.13279},{AP:22,DRJ:7.57015,DR:5.11403},{AP:23,DRJ:7.48005,DR:5.09601},{AP:24,DRJ:7.39348,DR:5.0787},{AP:25,DRJ:7.31032,DR:5.06206},{AP:26,DRJ:7.23041,DR:5.04608},{AP:27,DRJ:7.15365,DR:5.03073},{AP:28,DRJ:7.07992,DR:5.01598},{AP:29,DRJ:7.00913,DR:5.00183},{AP:30,DRJ:6.94119,DR:4.98824},{AP:31,DRJ:6.876,DR:4.9752},{AP:32,DRJ:6.8135,DR:4.9627},{AP:33,DRJ:6.75361,DR:4.95072},{AP:34,DRJ:6.69628,DR:4.93926},{AP:35,DRJ:6.64143,DR:4.92829},{AP:36,DRJ:6.58902,DR:4.9178},{AP:37,DRJ:6.53899,DR:4.9078},{AP:38,DRJ:6.4913,DR:4.89826},{AP:39,DRJ:6.4459,DR:4.88918},{AP:41,DRJ:6.36182,DR:4.87236},{AP:42,DRJ:6.32307,DR:4.86461},{AP:44,DRJ:6.25198,DR:4.8504},{AP:45,DRJ:6.21959,DR:4.84392},{AP:47,DRJ:6.16099,DR:4.8322},{AP:48,DRJ:6.13473,DR:4.82695},{AP:51,DRJ:6.06791,DR:4.81358},{AP:54,DRJ:6.01869,DR:4.80374},{AP:57,DRJ:6,DR:4.8}
  ],
  Pro : [
    {AP:0,DMG:42},{AP:3,DMG:42.8},{AP:6,DMG:43.6},{AP:9,DMG:44.4},{AP:10,DMG:44.6},{AP:12,DMG:45.1},{AP:13,DMG:45.3},{AP:15,DMG:45.7},{AP:16,DMG:46},{AP:18,DMG:46.4},{AP:19,DMG:46.6},{AP:20,DMG:46.8},{AP:21,DMG:47},{AP:22,DMG:47.2},{AP:23,DMG:47.3},{AP:24,DMG:47.5},{AP:25,DMG:47.7},{AP:26,DMG:47.9},{AP:27,DMG:48},{AP:28,DMG:48.2},{AP:29,DMG:48.3},{AP:30,DMG:48.5},{AP:31,DMG:48.6},{AP:32,DMG:48.8},{AP:33,DMG:48.9},{AP:34,DMG:49},{AP:35,DMG:49.2},{AP:36,DMG:49.3},{AP:37,DMG:49.4},{AP:38,DMG:49.5},{AP:39,DMG:49.6},{AP:41,DMG:49.8},{AP:42,DMG:49.9},{AP:44,DMG:49.9},{AP:45,DMG:49.9},{AP:47,DMG:49.9},{AP:48,DMG:49.9},{AP:51,DMG:49.9},{AP:54,DMG:49.9},{AP:57,DMG:49.9} 
  ],
  Gal96 : [
    {AP:0,DMG:62},{AP:3,DMG:63.4},{AP:6,DMG:64.9},{AP:9,DMG:66.2},{AP:10,DMG:66.6},{AP:12,DMG:67.5},{AP:13,DMG:67.9},{AP:15,DMG:68.7},{AP:16,DMG:69.1},{AP:18,DMG:69.8},{AP:19,DMG:70.2},{AP:20,DMG:70.5},{AP:21,DMG:70.8},{AP:22,DMG:71.2},{AP:23,DMG:71.5},{AP:24,DMG:71.8},{AP:25,DMG:72.1},{AP:26,DMG:72.4},{AP:27,DMG:72.7},{AP:28,DMG:73},{AP:29,DMG:73.3},{AP:30,DMG:73.5},{AP:31,DMG:73.8},{AP:32,DMG:74},{AP:33,DMG:74.3},{AP:34,DMG:74.5},{AP:35,DMG:74.7},{AP:36,DMG:74.9},{AP:37,DMG:75.1},{AP:38,DMG:75.3},{AP:39,DMG:75.5},{AP:41,DMG:75.9},{AP:42,DMG:76.1},{AP:44,DMG:76.4},{AP:45,DMG:76.5},{AP:47,DMG:76.7},{AP:48,DMG:76.9},{AP:51,DMG:77.2},{AP:54,DMG:77.4},{AP:57,DMG:77.5}
  ],
  Nzap : [
    {AP:0,DR:6,PR:11.6},{AP:3,DR:5.69426,PR:11.92792},{AP:6,DR:5.54818,PR:12.11569},{AP:9,DR:5.43593,PR:12.26694},{AP:10,DR:5.40318,PR:12.31203},{AP:12,DR:5.34296,PR:12.39597},{AP:13,DR:5.31512,PR:12.43519},{AP:15,DR:5.26333,PR:12.50883},{AP:16,DR:5.23917,PR:12.54347},{AP:18,DR:5.19391,PR:12.60882},{AP:19,DR:5.17269,PR:12.63968},{AP:20,DR:5.15233,PR:12.66939},{AP:21,DR:5.13279,PR:12.69802},{AP:22,DR:5.11403,PR:12.7256},{AP:23,DR:5.09601,PR:12.75218},{AP:24,DR:5.0787,PR:12.7778},{AP:25,DR:5.06206,PR:12.80249},{AP:26,DR:5.04608,PR:12.82627},{AP:27,DR:5.03073,PR:12.84917},{AP:28,DR:5.01598,PR:12.87123},{AP:29,DR:5.00183,PR:12.89245},{AP:30,DR:4.98824,PR:12.91287},{AP:31,DR:4.9752,PR:12.9325},{AP:32,DR:4.9627,PR:12.95136},{AP:33,DR:4.95072,PR:12.96946},{AP:34,DR:4.93926,PR:12.98683},{AP:35,DR:4.92829,PR:13.00347},{AP:36,DR:4.9178,PR:13.01939},{AP:37,DR:4.9078,PR:13.03461},{AP:38,DR:4.89826,PR:13.04915},{AP:39,DR:4.88918,PR:13.063},{AP:41,DR:4.87236,PR:13.0887},{AP:42,DR:4.86461,PR:13.10057},{AP:44,DR:4.8504,PR:13.12237},{AP:45,DR:4.84392,PR:13.13232},{AP:47,DR:4.8322,PR:13.15034},{AP:48,DR:4.82695,PR:13.15843},{AP:51,DR:4.81358,PR:13.17903},{AP:54,DR:4.80374,PR:13.19422},{AP:57,DR:4.8,PR:13.2}
  ],
  // EHのデフォルトは28.01%
  Luna : [
    {AP:0,PR:10,EH:10},{AP:3,PR:10.16743,EH:10.73205},{AP:6,PR:10.27386,EH:11.883},{AP:9,PR:10.36218,EH:13.22074},{AP:10,PR:10.38888,EH:13.69192},{AP:12,PR:10.43895,EH:14.65849},{AP:13,PR:10.4625,EH:15.15032},{AP:15,PR:10.50699,EH:16.14353},{AP:16,PR:10.52803,EH:16.64235},{AP:18,PR:10.5679,EH:17.63876},{AP:19,PR:10.5868,EH:18.13439},{AP:20,PR:10.60505,EH:18.62709},{AP:21,PR:10.62267,EH:19.11604},{AP:22,PR:10.63969,EH:19.60049},{AP:23,PR:10.65613,EH:20.07972},{AP:24,PR:10.672,EH:20.55305},{AP:25,PR:10.68732,EH:21.01986},{AP:26,PR:10.7021,EH:21.47956},{AP:27,PR:10.71637,EH:21.93159},{AP:28,PR:10.73013,EH:22.37542},{AP:29,PR:10.74339,EH:22.81055},{AP:30,PR:10.75616,EH:23.23652},{AP:31,PR:10.76845,EH:23.65289},{AP:32,PR:10.78028,EH:24.05923},{AP:33,PR:10.79165,EH:24.45515},{AP:34,PR:10.80257,EH:24.84027},{AP:35,PR:10.81304,EH:25.21424},{AP:36,PR:10.82307,EH:25.57672},{AP:37,PR:10.83267,EH:25.9274},{AP:38,PR:10.84184,EH:26.26597},{AP:39,PR:10.85059,EH:26.59215},{AP:41,PR:10.86684,EH:27.20628},{AP:42,PR:10.87435,EH:27.49375},{AP:44,PR:10.88817,EH:28.02836},{AP:45,PR:10.89448,EH:28.2751},{AP:47,PR:10.90592,EH:28.72653},{AP:48,PR:10.91106,EH:28.93089},{AP:51,PR:10.92415,EH:29.45683},{AP:54,PR:10.93382,EH:29.84961},{AP:57,PR:10.9375,EH:30}
  ],
  Blaster : [
    {AP:0,DRJ:10},{AP:3,DRJ:9.61372},{AP:6,DRJ:9.24688},{AP:9,DRJ:8.89948},{AP:10,DRJ:8.788},{AP:12,DRJ:8.57152},{AP:13,DRJ:8.46652},{AP:15,DRJ:8.263},{AP:16,DRJ:8.16448},{AP:18,DRJ:7.97392},{AP:19,DRJ:7.88188},{AP:20,DRJ:7.792},{AP:21,DRJ:7.70428},{AP:22,DRJ:7.61872},{AP:23,DRJ:7.53532},{AP:24,DRJ:7.45408},{AP:25,DRJ:7.375},{AP:26,DRJ:7.29808},{AP:27,DRJ:7.22332},{AP:28,DRJ:7.15072},{AP:29,DRJ:7.08028},{AP:30,DRJ:7.012},{AP:31,DRJ:6.94588},{AP:32,DRJ:6.88192},{AP:33,DRJ:6.82012},{AP:34,DRJ:6.76048},{AP:35,DRJ:6.703},{AP:36,DRJ:6.64768},{AP:37,DRJ:6.59452},{AP:38,DRJ:6.54352},{AP:39,DRJ:6.49468},{AP:41,DRJ:6.40348},{AP:42,DRJ:6.36112},{AP:44,DRJ:6.28288},{AP:45,DRJ:6.247},{AP:47,DRJ:6.18172},{AP:48,DRJ:6.15232},{AP:51,DRJ:6.07708},{AP:54,DRJ:6.02128},{AP:57,DRJ:6}
  ],
  Jet : [
    {AP:0,DR:3,IVR:1},{AP:3,DR:2.82901,IVR:1.00254},{AP:6,DR:2.72521,IVR:1.00691},{AP:9,DR:2.64022,IVR:1.0122},{AP:10,DR:2.61469,IVR:1.0141},{AP:12,DR:2.56697,IVR:1.01805},{AP:13,DR:2.54459,IVR:1.02007},{AP:15,DR:2.50242,IVR:1.0242},{AP:16,DR:2.48253,IVR:1.02629},{AP:18,DR:2.44491,IVR:1.03049},{AP:19,DR:2.42711,IVR:1.03259},{AP:20,DR:2.40995,IVR:1.03469},{AP:21,DR:2.39338,IVR:1.03677},{AP:22,DR:2.37741,IVR:1.03885},{AP:23,DR:2.36199,IVR:1.04091},{AP:24,DR:2.34712,IVR:1.04295},{AP:25,DR:2.33278,IVR:1.04497},{AP:26,DR:2.31894,IVR:1.04696},{AP:27,DR:2.30561,IVR:1.04892},{AP:28,DR:2.29276,IVR:1.05085},{AP:29,DR:2.28038,IVR:1.05275},{AP:30,DR:2.26846,IVR:1.05461},{AP:31,DR:2.257,IVR:1.05644},{AP:32,DR:2.24597,IVR:1.05822},{AP:33,DR:2.23539,IVR:1.05996},{AP:34,DR:2.22522,IVR:1.06165},{AP:35,DR:2.21548,IVR:1.0633},{AP:36,DR:2.20615,IVR:1.0649},{AP:37,DR:2.19723,IVR:1.06645},{AP:38,DR:2.18871,IVR:1.06795},{AP:39,DR:2.18058,IVR:1.0694},{AP:41,DR:2.16549,IVR:1.07212},{AP:42,DR:2.15851,IVR:1.0734},{AP:44,DR:2.1457,IVR:1.07578},{AP:45,DR:2.13985,IVR:1.07688},{AP:47,DR:2.12924,IVR:1.0789},{AP:48,DR:2.12449,IVR:1.07981},{AP:51,DR:2.11236,IVR:1.08217},{AP:54,DR:2.1034,IVR:1.08393},{AP:57,DR:2.1,IVR:1.0846}
  ],
  Clash : [
    {AP:0,DRJ:8},{AP:3,DRJ:7.61372},{AP:6,DRJ:7.24688},{AP:9,DRJ:6.89948},{AP:10,DRJ:6.788},{AP:12,DRJ:6.57152},{AP:13,DRJ:6.46652},{AP:15,DRJ:6.263},{AP:16,DRJ:6.16448},{AP:18,DRJ:5.97392},{AP:19,DRJ:5.88188},{AP:20,DRJ:5.792},{AP:21,DRJ:5.70428},{AP:22,DRJ:5.61872},{AP:23,DRJ:5.53532},{AP:24,DRJ:5.45408},{AP:25,DRJ:5.375},{AP:26,DRJ:5.29808},{AP:27,DRJ:5.22332},{AP:28,DRJ:5.15072},{AP:29,DRJ:5.08028},{AP:30,DRJ:5.012},{AP:31,DRJ:4.94588},{AP:32,DRJ:4.88192},{AP:33,DRJ:4.82012},{AP:34,DRJ:4.76048},{AP:35,DRJ:4.703},{AP:36,DRJ:4.64768},{AP:37,DRJ:4.59452},{AP:38,DRJ:4.54352},{AP:39,DRJ:4.49468},{AP:41,DRJ:4.40348},{AP:42,DRJ:4.36112},{AP:44,DRJ:4.28288},{AP:45,DRJ:4.247},{AP:47,DRJ:4.18172},{AP:48,DRJ:4.15232},{AP:51,DRJ:4.07708},{AP:54,DRJ:4.02128},{AP:57,DRJ:4}
  ],
  Range : [
    {AP:0,DRJ:8},{AP:3,DRJ:7.71029},{AP:6,DRJ:7.43516},{AP:9,DRJ:7.17461},{AP:10,DRJ:7.091},{AP:12,DRJ:6.92864},{AP:13,DRJ:6.84989},{AP:15,DRJ:6.69725},{AP:16,DRJ:6.62336},{AP:18,DRJ:6.48044},{AP:19,DRJ:6.41141},{AP:20,DRJ:6.344},{AP:21,DRJ:6.27821},{AP:22,DRJ:6.21404},{AP:23,DRJ:6.15149},{AP:24,DRJ:6.09056},{AP:25,DRJ:6.03125},{AP:26,DRJ:5.97356},{AP:27,DRJ:5.91749},{AP:28,DRJ:5.86304},{AP:29,DRJ:5.81021},{AP:30,DRJ:5.759},{AP:31,DRJ:5.70941},{AP:32,DRJ:5.66144},{AP:33,DRJ:5.61509},{AP:34,DRJ:5.57036},{AP:35,DRJ:5.52725},{AP:36,DRJ:5.48576},{AP:37,DRJ:5.44589},{AP:38,DRJ:5.40764},{AP:39,DRJ:5.37101},{AP:41,DRJ:5.30261},{AP:42,DRJ:5.27084},{AP:44,DRJ:5.21216},{AP:45,DRJ:5.18525},{AP:47,DRJ:5.13629},{AP:48,DRJ:5.11424},{AP:51,DRJ:5.05781},{AP:54,DRJ:5.01596},{AP:57,DRJ:5}
  ],
  Rapid : [
    {AP:0,DRJ:8,EH:33},{AP:3,DRJ:7.61372,EH:33.12625},{AP:6,DRJ:7.24688,EH:33.31499},{AP:9,DRJ:6.89948,EH:33.52951},{AP:10,DRJ:6.788,EH:33.60431},{AP:12,DRJ:6.57152,EH:33.75682},{AP:13,DRJ:6.46652,EH:33.83402},{AP:15,DRJ:6.263,EH:33.98921},{AP:16,DRJ:6.16448,EH:34.06684},{AP:18,DRJ:5.97392,EH:34.22136},{AP:19,DRJ:5.88188,EH:34.29797},{AP:20,DRJ:5.792,EH:34.37398},{AP:21,DRJ:5.70428,EH:34.44927},{AP:22,DRJ:5.61872,EH:34.52374},{AP:23,DRJ:5.53532,EH:34.59729},{AP:24,DRJ:5.45408,EH:34.66982},{AP:25,DRJ:5.375,EH:34.74125},{AP:26,DRJ:5.29808,EH:34.8115},{AP:27,DRJ:5.22332,EH:34.88048},{AP:28,DRJ:5.15072,EH:34.94814},{AP:29,DRJ:5.08028,EH:35},{AP:30,DRJ:5.012,EH:35},{AP:31,DRJ:4.94588,EH:35},{AP:32,DRJ:4.88192,EH:35},{AP:33,DRJ:4.82012,EH:35},{AP:34,DRJ:4.76048,EH:35},{AP:35,DRJ:4.703,EH:35},{AP:36,DRJ:4.64768,EH:35},{AP:37,DRJ:4.59452,EH:35},{AP:38,DRJ:4.54352,EH:35},{AP:39,DRJ:4.49468,EH:35},{AP:41,DRJ:4.40348,EH:35},{AP:42,DRJ:4.36112,EH:35},{AP:44,DRJ:4.28288,EH:35},{AP:45,DRJ:4.247,EH:35},{AP:47,DRJ:4.18172,EH:35},{AP:48,DRJ:4.15232,EH:35},{AP:51,DRJ:4.07708,EH:35},{AP:54,DRJ:4.02128,EH:35},{AP:57,DRJ:4,EH:35}
  ],
  RapidPro : [
    {AP:0,EH:33,DRJ:8},{AP:3,EH:33.12625,DRJ:7.61372},{AP:6,EH:33.31499,DRJ:7.24688},{AP:9,EH:33.52951,DRJ:6.89948},{AP:10,EH:33.60431,DRJ:6.788},{AP:12,EH:33.75682,DRJ:6.57152},{AP:13,EH:33.83402,DRJ:6.46652},{AP:15,EH:33.98921,DRJ:6.263},{AP:16,EH:34.06684,DRJ:6.16448},{AP:18,EH:34.22136,DRJ:5.97392},{AP:19,EH:34.29797,DRJ:5.88188},{AP:20,EH:34.37398,DRJ:5.792},{AP:21,EH:34.44927,DRJ:5.70428},{AP:22,EH:34.52374,DRJ:5.61872},{AP:23,EH:34.59729,DRJ:5.53532},{AP:24,EH:34.66982,DRJ:5.45408},{AP:25,EH:34.74125,DRJ:5.375},{AP:26,EH:34.8115,DRJ:5.29808},{AP:27,EH:34.88048,DRJ:5.22332},{AP:28,EH:34.94814,DRJ:5.15072},{AP:29,EH:35,DRJ:5.08028},{AP:30,EH:35,DRJ:5.012},{AP:31,EH:35,DRJ:4.94588},{AP:32,EH:35,DRJ:4.88192},{AP:33,EH:35,DRJ:4.82012},{AP:34,EH:35,DRJ:4.76048},{AP:35,EH:35,DRJ:4.703},{AP:36,EH:35,DRJ:4.64768},{AP:37,EH:35,DRJ:4.59452},{AP:38,EH:35,DRJ:4.54352},{AP:39,EH:35,DRJ:4.49468},{AP:41,EH:35,DRJ:4.40348},{AP:42,EH:35,DRJ:4.36112},{AP:44,EH:35,DRJ:4.28288},{AP:45,EH:35,DRJ:4.247},{AP:47,EH:35,DRJ:4.18172},{AP:48,EH:35,DRJ:4.15232},{AP:51,EH:35,DRJ:4.07708},{AP:54,EH:35,DRJ:4.02128},{AP:57,EH:35,DRJ:4}
  ],
  H3 : [
    {AP:0,DMG:41},{AP:3,DMG:41.9},{AP:6,DMG:42.8},{AP:9,DMG:43.7},{AP:10,DMG:43.9},{AP:12,DMG:44.5},{AP:13,DMG:44.7},{AP:15,DMG:45.2},{AP:16,DMG:45.5},{AP:18,DMG:45.9},{AP:19,DMG:46.2},{AP:20,DMG:46.4},{AP:21,DMG:46.6},{AP:22,DMG:46.8},{AP:23,DMG:47},{AP:24,DMG:47.2},{AP:25,DMG:47.4},{AP:26,DMG:47.6},{AP:27,DMG:47.8},{AP:28,DMG:48},{AP:29,DMG:48.1},{AP:30,DMG:48.3},{AP:31,DMG:48.5},{AP:32,DMG:48.6},{AP:33,DMG:48.8},{AP:34,DMG:48.9},{AP:35,DMG:49.1},{AP:36,DMG:49.2},{AP:37,DMG:49.3},{AP:38,DMG:49.5},{AP:39,DMG:49.6},{AP:41,DMG:49.8},{AP:42,DMG:49.9},{AP:44,DMG:49.9},{AP:45,DMG:49.9},{AP:47,DMG:49.9},{AP:48,DMG:49.9},{AP:51,DMG:49.9},{AP:54,DMG:49.9},{AP:57,DMG:49.9}
  ],
  L3 : [
    {AP:0,DMG:29},{AP:3,DMG:29.6},{AP:6,DMG:30.2},{AP:9,DMG:30.8},{AP:10,DMG:31},{AP:12,DMG:31.4},{AP:13,DMG:31.6},{AP:15,DMG:31.9},{AP:16,DMG:32.1},{AP:18,DMG:32.4},{AP:19,DMG:32.5},{AP:20,DMG:32.7},{AP:21,DMG:32.8},{AP:22,DMG:33},{AP:23,DMG:33.1},{AP:24,DMG:33.3},{AP:25,DMG:33.3},{AP:26,DMG:33.3},{AP:27,DMG:33.3},{AP:28,DMG:33.3},{AP:29,DMG:33.3},{AP:30,DMG:33.3},{AP:31,DMG:33.3},{AP:32,DMG:33.3},{AP:33,DMG:33.3},{AP:34,DMG:33.3},{AP:35,DMG:33.3},{AP:36,DMG:33.3},{AP:37,DMG:33.3},{AP:38,DMG:33.3},{AP:39,DMG:33.3},{AP:41,DMG:33.3},{AP:42,DMG:33.3},{AP:44,DMG:33.3},{AP:45,DMG:33.3},{AP:47,DMG:33.3},{AP:48,DMG:33.3},{AP:51,DMG:33.3},{AP:54,DMG:33.3},{AP:57,DMG:33.3}
  ],
  Squeezer : [
    {AP:0,DMG:38},{AP:3,DMG:39.1},{AP:6,DMG:40.1},{AP:9,DMG:41.1},{AP:10,DMG:41.4},{AP:12,DMG:42},{AP:13,DMG:42.3},{AP:15,DMG:42.9},{AP:16,DMG:43.2},{AP:18,DMG:43.7},{AP:19,DMG:44},{AP:20,DMG:44.2},{AP:21,DMG:44.5},{AP:22,DMG:44.7},{AP:23,DMG:45},{AP:24,DMG:45.2},{AP:25,DMG:45.4},{AP:26,DMG:45.7},{AP:27,DMG:45.9},{AP:28,DMG:46.1},{AP:29,DMG:46.3},{AP:30,DMG:46.5},{AP:31,DMG:46.7},{AP:32,DMG:46.8},{AP:33,DMG:47},{AP:34,DMG:47.2},{AP:35,DMG:47.3},{AP:36,DMG:47.5},{AP:37,DMG:47.7},{AP:38,DMG:47.8},{AP:39,DMG:47.9},{AP:41,DMG:48.2},{AP:42,DMG:48.3},{AP:44,DMG:48.5},{AP:45,DMG:48.6},{AP:47,DMG:48.8},{AP:48,DMG:48.9},{AP:51,DMG:49.1},{AP:54,DMG:49.3},{AP:57,DMG:49.4}
  ],
  Carbon : [
    {AP:0,DMG:1},{AP:3,DMG:1.01449},{AP:6,DMG:1.02824},{AP:9,DMG:1.04127},{AP:10,DMG:1.04545},{AP:12,DMG:1.05357},{AP:13,DMG:1.05751},{AP:15,DMG:1.06514},{AP:16,DMG:1.06883},{AP:18,DMG:1.07598},{AP:19,DMG:1.07943},{AP:20,DMG:1.0828},{AP:21,DMG:1.08609},{AP:22,DMG:1.0893},{AP:23,DMG:1.09243},{AP:24,DMG:1.09547},{AP:25,DMG:1.09844},{AP:26,DMG:1.10132},{AP:27,DMG:1.10413},{AP:28,DMG:1.10685},{AP:29,DMG:1.10949},{AP:30,DMG:1.11205},{AP:31,DMG:1.11453},{AP:32,DMG:1.11693},{AP:33,DMG:1.11925},{AP:34,DMG:1.12148},{AP:35,DMG:1.12364},{AP:36,DMG:1.12571},{AP:37,DMG:1.12771},{AP:38,DMG:1.12962},{AP:39,DMG:1.13145},{AP:41,DMG:1.13487},{AP:42,DMG:1.13646},{AP:44,DMG:1.13939},{AP:45,DMG:1.14074},{AP:47,DMG:1.14319},{AP:48,DMG:1.14429},{AP:51,DMG:1.14711},{AP:54,DMG:1.1492},{AP:57,DMG:1.15}
  ],
  SplatRoller : [
    {AP:0,DMG:1},{AP:3,DMG:1.03822},{AP:6,DMG:1.05648},{AP:9,DMG:1.07051},{AP:10,DMG:1.0746},{AP:12,DMG:1.08213},{AP:13,DMG:1.08561},{AP:15,DMG:1.09208},{AP:16,DMG:1.0951},{AP:18,DMG:1.10076},{AP:19,DMG:1.10341},{AP:20,DMG:1.10596},{AP:21,DMG:1.1084},{AP:22,DMG:1.11075},{AP:23,DMG:1.113},{AP:24,DMG:1.11516},{AP:25,DMG:1.11724},{AP:26,DMG:1.11924},{AP:27,DMG:1.12116},{AP:28,DMG:1.123},{AP:29,DMG:1.12477},{AP:30,DMG:1.12647},{AP:31,DMG:1.1281},{AP:32,DMG:1.12966},{AP:33,DMG:1.13116},{AP:34,DMG:1.13259},{AP:35,DMG:1.13396},{AP:36,DMG:1.13527},{AP:37,DMG:1.13653},{AP:38,DMG:1.13772},{AP:39,DMG:1.13885},{AP:41,DMG:1.14095},{AP:42,DMG:1.14192},{AP:44,DMG:1.1437},{AP:45,DMG:1.14451},{AP:47,DMG:1.14598},{AP:48,DMG:1.14663},{AP:51,DMG:1.1483},{AP:54,DMG:1.14953},{AP:57,DMG:1.15 }
  ],
  Dynamo : [
    {AP:0,DMG:1},{AP:3,DMG:1.01449},{AP:6,DMG:1.02824},{AP:9,DMG:1.04127},{AP:10,DMG:1.04545},{AP:12,DMG:1.05357},{AP:13,DMG:1.05751},{AP:15,DMG:1.06514},{AP:16,DMG:1.06883},{AP:18,DMG:1.07598},{AP:19,DMG:1.07943},{AP:20,DMG:1.0828},{AP:21,DMG:1.08609},{AP:22,DMG:1.0893},{AP:23,DMG:1.09243},{AP:24,DMG:1.09547},{AP:25,DMG:1.09844},{AP:26,DMG:1.10132},{AP:27,DMG:1.10413},{AP:28,DMG:1.10685},{AP:29,DMG:1.10949},{AP:30,DMG:1.11205},{AP:31,DMG:1.11453},{AP:32,DMG:1.11693},{AP:33,DMG:1.11925},{AP:34,DMG:1.12148},{AP:35,DMG:1.12364},{AP:36,DMG:1.12571},{AP:37,DMG:1.12771},{AP:38,DMG:1.12962},{AP:39,DMG:1.13145},{AP:41,DMG:1.13487},{AP:42,DMG:1.13646},{AP:44,DMG:1.13939},{AP:45,DMG:1.14074},{AP:47,DMG:1.14319},{AP:48,DMG:1.14429},{AP:51,DMG:1.14711},{AP:54,DMG:1.1492},{AP:57,DMG:1.15}
  ],
  Flingza : [
    {AP:0,DMG:1},{AP:3,DMG:1.03822},{AP:6,DMG:1.05648},{AP:9,DMG:1.07051},{AP:10,DMG:1.0746},{AP:12,DMG:1.08213},{AP:13,DMG:1.08561},{AP:15,DMG:1.09208},{AP:16,DMG:1.0951},{AP:18,DMG:1.10076},{AP:19,DMG:1.10341},{AP:20,DMG:1.10596},{AP:21,DMG:1.1084},{AP:22,DMG:1.11075},{AP:23,DMG:1.113},{AP:24,DMG:1.11516},{AP:25,DMG:1.11724},{AP:26,DMG:1.11924},{AP:27,DMG:1.12116},{AP:28,DMG:1.123},{AP:29,DMG:1.12477},{AP:30,DMG:1.12647},{AP:31,DMG:1.1281},{AP:32,DMG:1.12966},{AP:33,DMG:1.13116},{AP:34,DMG:1.13259},{AP:35,DMG:1.13396},{AP:36,DMG:1.13527},{AP:37,DMG:1.13653},{AP:38,DMG:1.13772},{AP:39,DMG:1.13885},{AP:41,DMG:1.14095},{AP:42,DMG:1.14192},{AP:44,DMG:1.1437},{AP:45,DMG:1.14451},{AP:47,DMG:1.14598},{AP:48,DMG:1.14663},{AP:51,DMG:1.1483},{AP:54,DMG:1.14953},{AP:57,DMG:1.15}
  ],
  Octobrush : [
    {AP:0,ITW:1,MS:1.68},{AP:3,ITW:1.05794,MS:1.72132},{AP:6,ITW:1.11297,MS:1.74498},{AP:9,ITW:1.16508,MS:1.76403},{AP:10,ITW:1.1818,MS:1.76972},{AP:12,ITW:1.21427,MS:1.78029},{AP:13,ITW:1.23002,MS:1.78523},{AP:15,ITW:1.26055,MS:1.79451},{AP:16,ITW:1.27533,MS:1.79888},{AP:18,ITW:1.30391,MS:1.80711},{AP:19,ITW:1.31772,MS:1.811},{AP:20,ITW:1.3312,MS:1.81474},{AP:21,ITW:1.34436,MS:1.81835},{AP:22,ITW:1.35719,MS:1.82183},{AP:23,ITW:1.3697,MS:1.82518},{AP:24,ITW:1.38189,MS:1.8284},{AP:25,ITW:1.39375,MS:1.83151},{AP:26,ITW:1.40529,MS:1.83451},{AP:27,ITW:1.4165,MS:1.8374},{AP:28,ITW:1.42739,MS:1.84017},{AP:29,ITW:1.43796,MS:1.84285},{AP:30,ITW:1.4482,MS:1.84542},{AP:31,ITW:1.45812,MS:1.8479},{AP:32,ITW:1.46771,MS:1.85027},{AP:33,ITW:1.47698,MS:1.85255},{AP:34,ITW:1.48593,MS:1.85474},{AP:35,ITW:1.49455,MS:1.85684},{AP:36,ITW:1.50285,MS:1.85884},{AP:37,ITW:1.51082,MS:1.86076},{AP:38,ITW:1.51847,MS:1.86259},{AP:39,ITW:1.5258,MS:1.86434},{AP:41,ITW:1.53948,MS:1.86758},{AP:42,ITW:1.54583,MS:1.86907},{AP:44,ITW:1.55757,MS:1.87182},{AP:45,ITW:1.56295,MS:1.87307},{AP:47,ITW:1.57274,MS:1.87534},{AP:48,ITW:1.57715,MS:1.87636},{AP:51,ITW:1.58844,MS:1.87896},{AP:54,ITW:1.59681,MS:1.88087},{AP:57,ITW:1.6,MS:1.8816}
  ],
  Inkbrush : [
    {AP:0,ITW:1,MS:1.92},{AP:3,ITW:1.1223,MS:1.94713},{AP:6,ITW:1.18073,MS:1.95893},{AP:9,ITW:1.22563,MS:1.96779},{AP:10,ITW:1.23873,MS:1.97034},{AP:12,ITW:1.26282,MS:1.97502},{AP:13,ITW:1.27395,MS:1.97717},{AP:15,ITW:1.29467,MS:1.98116},{AP:16,ITW:1.30433,MS:1.98301},{AP:18,ITW:1.32243,MS:1.98646},{AP:19,ITW:1.33092,MS:1.98808},{AP:20,ITW:1.33907,MS:1.98963},{AP:21,ITW:1.34688,MS:1.99111},{AP:22,ITW:1.35439,MS:1.99253},{AP:23,ITW:1.3616,MS:1.99389},{AP:24,ITW:1.36852,MS:1.9952},{AP:25,ITW:1.37517,MS:1.99645},{AP:26,ITW:1.38157,MS:1.99765},{AP:27,ITW:1.38771,MS:1.99881},{AP:28,ITW:1.39361,MS:1.99992},{AP:29,ITW:1.39927,MS:2.00098},{AP:30,ITW:1.4047,MS:2.002},{AP:31,ITW:1.40992,MS:2.00297},{AP:32,ITW:1.41492,MS:2.00391},{AP:33,ITW:1.41971,MS:2.0048},{AP:34,ITW:1.4243,MS:2.00566},{AP:35,ITW:1.42869,MS:2.00648},{AP:36,ITW:1.43288,MS:2.00726},{AP:37,ITW:1.43688,MS:2.008},{AP:38,ITW:1.4407,MS:2.00871},{AP:39,ITW:1.44433,MS:2.00939},{AP:41,ITW:1.45105,MS:2.01064},{AP:42,ITW:1.45415,MS:2.01121},{AP:44,ITW:1.45984,MS:2.01227},{AP:45,ITW:1.46243,MS:2.01275},{AP:47,ITW:1.46712,MS:2.01362},{AP:48,ITW:1.46922,MS:2.01401},{AP:51,ITW:1.47457,MS:2.015},{AP:54,ITW:1.4785,MS:2.01572},{AP:57,ITW:1.48,MS:2.016}
  ],
  Squiffer : [
    {AP:0,range:167.64999,IC:1},{AP:3,range:169.09854,IC:1.02318},{AP:6,range:170.47419,IC:1.04519},{AP:9,range:171.77694,IC:1.06603},{AP:10,range:172.19499,IC:1.07272},{AP:12,range:173.00679,IC:1.08571},{AP:13,range:173.40054,IC:1.09201},{AP:15,range:174.16374,IC:1.10422},{AP:16,range:174.53319,IC:1.11013},{AP:18,range:175.24779,IC:1.12156},{AP:19,range:175.59294,IC:1.12709},{AP:20,range:175.92999,IC:1.13248},{AP:21,range:176.25894,IC:1.13774},{AP:22,range:176.57979,IC:1.14288},{AP:23,range:176.89254,IC:1.14788},{AP:24,range:177.19719,IC:1.15276},{AP:25,range:177.49374,IC:1.1575},{AP:26,range:177.78219,IC:1.16212},{AP:27,range:178.06254,IC:1.1666},{AP:28,range:178.33479,IC:1.17096},{AP:29,range:178.59894,IC:1.17518},{AP:30,range:178.85499,IC:1.17928},{AP:31,range:179.10294,IC:1.18325},{AP:32,range:179.34279,IC:1.18708},{AP:33,range:179.57454,IC:1.19079},{AP:34,range:179.79819,IC:1.19437},{AP:35,range:180.01374,IC:1.19782},{AP:36,range:180.22119,IC:1.20114},{AP:37,range:180.42054,IC:1.20433},{AP:38,range:180.61179,IC:1.20739},{AP:39,range:180.79494,IC:1.21032},{AP:41,range:181.13694,IC:1.21579},{AP:42,range:181.29579,IC:1.21833},{AP:44,range:181.58919,IC:1.22303},{AP:45,range:181.72374,IC:1.22518},{AP:47,range:181.96854,IC:1.2291},{AP:48,range:182.07879,IC:1.23086},{AP:51,range:182.36094,IC:1.23538},{AP:54,range:182.57019,IC:1.23872},{AP:57,range:182.64999,IC:1.24}
  ],
  Charger : [
    {AP:0,DMG:160},{AP:3,DMG:163},{AP:6,DMG:166},{AP:9,DMG:168.8},{AP:10,DMG:169.6},{AP:12,DMG:171.4},{AP:13,DMG:172.2},{AP:15,DMG:173.8},{AP:16,DMG:174.6},{AP:18,DMG:176.2},{AP:19,DMG:176.9},{AP:20,DMG:177.6},{AP:21,DMG:178.3},{AP:22,DMG:179},{AP:23,DMG:179.7},{AP:24,DMG:180.3},{AP:25,DMG:181},{AP:26,DMG:181.6},{AP:27,DMG:182.2},{AP:28,DMG:182.7},{AP:29,DMG:183.3},{AP:30,DMG:183.9},{AP:31,DMG:184.4},{AP:32,DMG:184.9},{AP:33,DMG:185.4},{AP:34,DMG:185.9},{AP:35,DMG:186.3},{AP:36,DMG:186.8},{AP:37,DMG:187.2},{AP:38,DMG:187.6},{AP:39,DMG:188},{AP:41,DMG:188.7},{AP:42,DMG:189.1},{AP:44,DMG:189.7},{AP:45,DMG:190},{AP:47,DMG:190.5},{AP:48,DMG:190.7},{AP:51,DMG:191.3},{AP:54,DMG:191.8},{AP:57,DMG:192}
  ],
  Bamboo : [
    {AP:0,DMG:85},{AP:3,DMG:86.5},{AP:6,DMG:88},{AP:9,DMG:89.4},{AP:10,DMG:89.8},{AP:12,DMG:90.7},{AP:13,DMG:91.1},{AP:15,DMG:91.9},{AP:16,DMG:92.3},{AP:18,DMG:93.1},{AP:19,DMG:93.5},{AP:20,DMG:93.8},{AP:21,DMG:94.2},{AP:22,DMG:94.5},{AP:23,DMG:94.8},{AP:24,DMG:95.2},{AP:25,DMG:95.5},{AP:26,DMG:95.8},{AP:27,DMG:96.1},{AP:28,DMG:96.4},{AP:29,DMG:96.7},{AP:30,DMG:97},{AP:31,DMG:97.2},{AP:32,DMG:97.5},{AP:33,DMG:97.7},{AP:34,DMG:98},{AP:35,DMG:98.2},{AP:36,DMG:98.4},{AP:37,DMG:98.6},{AP:38,DMG:98.8},{AP:39,DMG:99},{AP:41,DMG:99.4},{AP:42,DMG:99.6},{AP:44,DMG:99.9},{AP:45,DMG:99.9},{AP:47,DMG:99.9},{AP:48,DMG:99.9},{AP:51,DMG:99.9},{AP:54,DMG:99.9},{AP:57,DMG:99.9} 
  ],
  Eliter : [
    {AP:0,range:290.5,IC:1},{AP:3,range:291.94855,IC:1.05252},{AP:6,range:293.3242,IC:1.07346},{AP:9,range:294.62695,IC:1.08888},{AP:10,range:295.045,IC:1.0933},{AP:12,range:295.8568,IC:1.10133},{AP:13,range:296.25055,IC:1.10501},{AP:15,range:297.01375,IC:1.11179},{AP:16,range:297.3832,IC:1.11494},{AP:18,range:298.0978,IC:1.12078},{AP:19,range:298.44295,IC:1.12351},{AP:20,range:298.78,IC:1.12612},{AP:21,range:299.10895,IC:1.12861},{AP:22,range:299.4298,IC:1.131},{AP:23,range:299.74255,IC:1.13328},{AP:24,range:300.0472,IC:1.13547},{AP:25,range:300.34375,IC:1.13757},{AP:26,range:300.6322,IC:1.13958},{AP:27,range:300.91255,IC:1.14151},{AP:28,range:301.1848,IC:1.14336},{AP:29,range:301.44895,IC:1.14513},{AP:30,range:301.705,IC:1.14682},{AP:31,range:301.95295,IC:1.14845},{AP:32,range:302.1928,IC:1.15},{AP:33,range:302.42455,IC:1.15149},{AP:34,range:302.6482,IC:1.15291},{AP:35,range:302.86375,IC:1.15427},{AP:36,range:303.0712,IC:1.15556},{AP:37,range:303.27055,IC:1.1568},{AP:38,range:303.4618,IC:1.15797},{AP:39,range:303.64495,IC:1.15909},{AP:41,range:303.98695,IC:1.16116},{AP:42,range:304.1458,IC:1.16211},{AP:44,range:304.4392,IC:1.16385},{AP:45,range:304.57375,IC:1.16464},{AP:47,range:304.81855,IC:1.16607},{AP:48,range:304.9288,IC:1.16672},{AP:51,range:305.21095,IC:1.16835},{AP:54,range:305.4202,IC:1.16954},{AP:57,range:305.5,IC:1.17}
  ],
  Gootuber : [
    {AP:0,DMG:180},{AP:3,DMG:182.6},{AP:6,DMG:185},{AP:9,DMG:187.4},{AP:10,DMG:188.1},{AP:12,DMG:189.6},{AP:13,DMG:190.3},{AP:15,DMG:191.7},{AP:16,DMG:192.3},{AP:18,DMG:193.6},{AP:19,DMG:194.2},{AP:20,DMG:194.9},{AP:21,DMG:195.4},{AP:22,DMG:196},{AP:23,DMG:196.6},{AP:24,DMG:197.1},{AP:25,DMG:197.7},{AP:26,DMG:198.2},{AP:27,DMG:198.7},{AP:28,DMG:199.2},{AP:29,DMG:199.7},{AP:30,DMG:200.1},{AP:31,DMG:200.6},{AP:32,DMG:201},{AP:33,DMG:201.4},{AP:34,DMG:201.8},{AP:35,DMG:202.2},{AP:36,DMG:202.6},{AP:37,DMG:202.9},{AP:38,DMG:203.3},{AP:39,DMG:203.6},{AP:41,DMG:204.2},{AP:42,DMG:204.5},{AP:44,DMG:205},{AP:45,DMG:205.3},{AP:47,DMG:205.7},{AP:48,DMG:205.9},{AP:51,DMG:206.4},{AP:54,DMG:206.8},{AP:57,DMG:207}
  ],
  Slosher : [
    {AP:0,height:-15,heightDown:-76.25},{AP:3,height:-18.8628,heightDown:-80.1128},{AP:6,height:-22.5312,heightDown:-83.7812},{AP:9,height:-26.0052,heightDown:-87.2552},{AP:10,height:-27.12,heightDown:-88.37},{AP:12,height:-29.2848,heightDown:-90.5348},{AP:13,height:-30.3348,heightDown:-91.5848},{AP:15,height:-32.37,heightDown:-93.62},{AP:16,height:-33.3552,heightDown:-94.6052},{AP:18,height:-35.2608,heightDown:-96.5108},{AP:19,height:-36.1812,heightDown:-97.4312},{AP:20,height:-37.08,heightDown:-98.33},{AP:21,height:-37.9572,heightDown:-99.2072},{AP:22,height:-38.8128,heightDown:-100.0628},{AP:23,height:-39.6468,heightDown:-100.8968},{AP:24,height:-40.4592,heightDown:-101.7092},{AP:25,height:-41.25,heightDown:-102.5},{AP:26,height:-42.0192,heightDown:-103.2692},{AP:27,height:-42.7668,heightDown:-104.0168},{AP:28,height:-43.4928,heightDown:-104.7428},{AP:29,height:-44.1972,heightDown:-105.4472},{AP:30,height:-44.88,heightDown:-106.13},{AP:31,height:-45.5412,heightDown:-106.7912},{AP:32,height:-46.1808,heightDown:-107.4308},{AP:33,height:-46.7988,heightDown:-108.0488},{AP:34,height:-47.3952,heightDown:-108.6452},{AP:35,height:-47.97,heightDown:-109.22},{AP:36,height:-48.5232,heightDown:-109.7732},{AP:37,height:-49.0548,heightDown:-110.3048},{AP:38,height:-49.5648,heightDown:-110.8148},{AP:39,height:-50.0532,heightDown:-111.3032},{AP:41,height:-50.9652,heightDown:-112.2152},{AP:42,height:-51.3888,heightDownDown:-112.6388},{AP:44,height:-52.1712,heightDown:-113.4212},{AP:45,height:-52.53,heightDown:-113.78},{AP:47,height:-53.1828,heightDown:-114.4328},{AP:48,height:-53.4768,heightDown:-114.7268},{AP:51,height:-54.2292,heightDown:-115.4792},{AP:54,height:-54.7872,heightDown:-116.0372},{AP:57,height:-55,heightDown:-116.25}
  ],
  Tri : [
    {AP:0,IC:1},{AP:3,IC:1.01931},{AP:6,IC:1.03766},{AP:9,IC:1.05503},{AP:10,IC:1.0606},{AP:12,IC:1.07142},{AP:13,IC:1.07667},{AP:15,IC:1.08685},{AP:16,IC:1.09178},{AP:18,IC:1.1013},{AP:19,IC:1.10591},{AP:20,IC:1.1104},{AP:21,IC:1.11479},{AP:22,IC:1.11906},{AP:23,IC:1.12323},{AP:24,IC:1.1273},{AP:25,IC:1.13125},{AP:26,IC:1.1351},{AP:27,IC:1.13883},{AP:28,IC:1.14246},{AP:29,IC:1.14599},{AP:30,IC:1.1494},{AP:31,IC:1.15271},{AP:32,IC:1.1559},{AP:33,IC:1.15899},{AP:34,IC:1.16198},{AP:35,IC:1.16485},{AP:36,IC:1.16762},{AP:37,IC:1.17027},{AP:38,IC:1.17282},{AP:39,IC:1.17527},{AP:41,IC:1.17983},{AP:42,IC:1.18194},{AP:44,IC:1.18586},{AP:45,IC:1.18765},{AP:47,IC:1.19091},{AP:48,IC:1.19238},{AP:51,IC:1.19615},{AP:54,IC:1.19894},{AP:57,IC:1.2}
  ],
  Machine : [
    {AP:0,IC:1},{AP:3,IC:1.01449},{AP:6,IC:1.02824},{AP:9,IC:1.04127},{AP:10,IC:1.04545},{AP:12,IC:1.05357},{AP:13,IC:1.05751},{AP:15,IC:1.06514},{AP:16,IC:1.06883},{AP:18,IC:1.07598},{AP:19,IC:1.07943},{AP:20,IC:1.0828},{AP:21,IC:1.08609},{AP:22,IC:1.0893},{AP:23,IC:1.09243},{AP:24,IC:1.09547},{AP:25,IC:1.09844},{AP:26,IC:1.10132},{AP:27,IC:1.10413},{AP:28,IC:1.10685},{AP:29,IC:1.10949},{AP:30,IC:1.11205},{AP:31,IC:1.11453},{AP:32,IC:1.11693},{AP:33,IC:1.11925},{AP:34,IC:1.12148},{AP:35,IC:1.12364},{AP:36,IC:1.12571},{AP:37,IC:1.12771},{AP:38,IC:1.12962},{AP:39,IC:1.13145},{AP:41,IC:1.13487},{AP:42,IC:1.13646},{AP:44,IC:1.13939},{AP:45,IC:1.14074},{AP:47,IC:1.14319},{AP:48,IC:1.14429},{AP:51,IC:1.14711},{AP:54,IC:1.1492},{AP:57,IC:1.15}
  ],
  Explosher : [
    {AP:0,PR:1},{AP:3,PR:1.02897},{AP:6,PR:1.05648},{AP:9,PR:1.08254},{AP:10,PR:1.0909},{AP:12,PR:1.10714},{AP:13,PR:1.11501},{AP:15,PR:1.13027},{AP:16,PR:1.13766},{AP:18,PR:1.15196},{AP:19,PR:1.15886},{AP:20,PR:1.1656},{AP:21,PR:1.17218},{AP:22,PR:1.1786},{AP:23,PR:1.18485},{AP:24,PR:1.19094},{AP:25,PR:1.19687},{AP:26,PR:1.20264},{AP:27,PR:1.20825},{AP:28,PR:1.2137},{AP:29,PR:1.21898},{AP:30,PR:1.2241},{AP:31,PR:1.22906},{AP:32,PR:1.23386},{AP:33,PR:1.23849},{AP:34,PR:1.24296},{AP:35,PR:1.24727},{AP:36,PR:1.25142},{AP:37,PR:1.25541},{AP:38,PR:1.25924},{AP:39,PR:1.2629},{AP:41,PR:1.26974},{AP:42,PR:1.27292},{AP:44,PR:1.27878},{AP:45,PR:1.28147},{AP:47,PR:1.28637},{AP:48,PR:1.28858},{AP:51,PR:1.29422},{AP:54,PR:1.2984},{AP:57,PR:1.3}
  ],
  Blob : [
    {AP:0,PR:1},{AP:3,PR:1.01931},{AP:6,PR:1.03766},{AP:9,PR:1.05503},{AP:10,PR:1.0606},{AP:12,PR:1.07142},{AP:13,PR:1.07667},{AP:15,PR:1.08685},{AP:16,PR:1.09178},{AP:18,PR:1.1013},{AP:19,PR:1.10591},{AP:20,PR:1.1104},{AP:21,PR:1.11479},{AP:22,PR:1.11906},{AP:23,PR:1.12323},{AP:24,PR:1.1273},{AP:25,PR:1.13125},{AP:26,PR:1.1351},{AP:27,PR:1.13883},{AP:28,PR:1.14246},{AP:29,PR:1.14599},{AP:30,PR:1.1494},{AP:31,PR:1.15271},{AP:32,PR:1.1559},{AP:33,PR:1.15899},{AP:34,PR:1.16198},{AP:35,PR:1.16485},{AP:36,PR:1.16762},{AP:37,PR:1.17027},{AP:38,PR:1.17282},{AP:39,PR:1.17527},{AP:41,PR:1.17983},{AP:42,PR:1.18194},{AP:44,PR:1.18586},{AP:45,PR:1.18765},{AP:47,PR:1.19091},{AP:48,PR:1.19238},{AP:51,PR:1.19615},{AP:54,PR:1.19894},{AP:57,PR:1.2}
  ],
  // デフォルトの弾数は19
  Mini : [
    {AP:0,frame:108},{AP:3,frame:121},{AP:6,frame:125},{AP:9,frame:129},{AP:10,frame:130},{AP:12,frame:131},{AP:13,frame:132},{AP:15,frame:134},{AP:16,frame:134},{AP:18,frame:136},{AP:19,frame:136},{AP:20,frame:137},{AP:21,frame:137},{AP:22,frame:138},{AP:23,frame:138},{AP:24,frame:139},{AP:25,frame:139},{AP:26,frame:140},{AP:27,frame:140},{AP:28,frame:141},{AP:29,frame:141},{AP:30,frame:141},{AP:31,frame:142},{AP:32,frame:142},{AP:33,frame:142},{AP:34,frame:143},{AP:35,frame:143},{AP:36,frame:143},{AP:37,frame:143},{AP:38,frame:144},{AP:39,frame:144},{AP:41,frame:144},{AP:42,frame:145},{AP:44,frame:145},{AP:45,frame:145},{AP:47,frame:145},{AP:48,frame:146},{AP:51,frame:146},{AP:54,frame:146},{AP:57,frame:146}
  ],
  // デフォルトの弾数は37
  Heavy : [
    {AP:0,frame:216},{AP:3,frame:222},{AP:6,frame:226},{AP:9,frame:231},{AP:10,frame:232},{AP:12,frame:235},{AP:13,frame:236},{AP:15,frame:239},{AP:16,frame:240},{AP:18,frame:243},{AP:19,frame:244},{AP:20,frame:245},{AP:21,frame:246},{AP:22,frame:247},{AP:23,frame:248},{AP:24,frame:249},{AP:25,frame:251},{AP:26,frame:252},{AP:27,frame:252},{AP:28,frame:253},{AP:29,frame:254},{AP:30,frame:255},{AP:31,frame:256},{AP:32,frame:257},{AP:33,frame:258},{AP:34,frame:258},{AP:35,frame:259},{AP:36,frame:260},{AP:37,frame:261},{AP:38,frame:261},{AP:39,frame:262},{AP:41,frame:263},{AP:42,frame:264},{AP:44,frame:265},{AP:45,frame:265},{AP:47,frame:266},{AP:48,frame:266},{AP:51,frame:267},{AP:54,frame:268},{AP:57,frame:268} 
  ],
  Ballpoint : [
    {AP:0,DMG:28},{AP:3,DMG:28.2},{AP:6,DMG:28.5},{AP:9,DMG:28.7},{AP:10,DMG:28.8},{AP:12,DMG:28.9},{AP:13,DMG:29},{AP:15,DMG:29.2},{AP:16,DMG:29.2},{AP:18,DMG:29.4},{AP:19,DMG:29.4},{AP:20,DMG:29.5},{AP:21,DMG:29.6},{AP:22,DMG:29.6},{AP:23,DMG:29.7},{AP:24,DMG:29.7},{AP:25,DMG:29.8},{AP:26,DMG:29.8},{AP:27,DMG:29.9},{AP:28,DMG:29.9},{AP:29,DMG:30},{AP:30,DMG:30},{AP:31,DMG:30.1},{AP:32,DMG:30.1},{AP:33,DMG:30.2},{AP:34,DMG:30.2},{AP:35,DMG:30.3},{AP:36,DMG:30.3},{AP:37,DMG:30.3},{AP:38,DMG:30.4},{AP:39,DMG:30.4},{AP:41,DMG:30.5},{AP:42,DMG:30.5},{AP:44,DMG:30.6},{AP:45,DMG:30.6},{AP:47,DMG:30.6},{AP:48,DMG:30.6},{AP:51,DMG:30.7},{AP:54,DMG:30.7},{AP:57,DMG:30.8}
  ],
  BallpointFar : [
    {AP:0,DMG:30},{AP:3,DMG:30.2},{AP:6,DMG:30.5},{AP:9,DMG:30.8},{AP:10,DMG:30.9},{AP:12,DMG:31.0},{AP:13,DMG:31.1},{AP:15,DMG:31.3},{AP:16,DMG:31.3},{AP:18,DMG:31.5},{AP:19,DMG:31.5},{AP:20,DMG:31.6},{AP:21,DMG:31.7},{AP:22,DMG:31.7},{AP:23,DMG:31.8},{AP:24,DMG:31.9},{AP:25,DMG:31.9},{AP:26,DMG:32.0},{AP:27,DMG:32.0},{AP:28,DMG:32.1},{AP:29,DMG:32.1},{AP:30,DMG:32.2},{AP:31,DMG:32.2},{AP:32,DMG:32.3},{AP:33,DMG:32.3},{AP:34,DMG:32.4},{AP:35,DMG:32.4},{AP:36,DMG:32.5},{AP:37,DMG:32.5},{AP:38,DMG:32.5},{AP:39,DMG:32.6},{AP:41,DMG:32.6},{AP:42,DMG:32.7},{AP:44,DMG:32.7},{AP:45,DMG:32.8},{AP:47,DMG:32.8},{AP:48,DMG:32.8},{AP:51,DMG:32.9},{AP:54,DMG:32.9},{AP:57,DMG:33.0}
  ],
  Hydra : [
    {AP:0,DMG:32,DMGFAR:40},{AP:3,DMG:32.3,DMGFAR:40.7},{AP:6,DMG:32.6,DMGFAR:41.5},{AP:9,DMG:32.8,DMGFAR:42.2},{AP:10,DMG:32.9,DMGFAR:42.4},{AP:12,DMG:33.1,DMGFAR:42.8},{AP:13,DMG:33.2,DMGFAR:43},{AP:15,DMG:33.3,DMGFAR:43.4},{AP:16,DMG:33.3,DMGFAR:43.6},{AP:18,DMG:33.3,DMGFAR:44},{AP:19,DMG:33.3,DMGFAR:44.2},{AP:20,DMG:33.3,DMGFAR:44.4},{AP:21,DMG:33.3,DMGFAR:44.5},{AP:22,DMG:33.3,DMGFAR:44.7},{AP:23,DMG:33.3,DMGFAR:44.9},{AP:24,DMG:33.3,DMGFAR:45},{AP:25,DMG:33.3,DMGFAR:45.2},{AP:26,DMG:33.3,DMGFAR:45.4},{AP:27,DMG:33.3,DMGFAR:45.5},{AP:28,DMG:33.3,DMGFAR:45.6},{AP:29,DMG:33.3,DMGFAR:45.8},{AP:30,DMG:33.3,DMGFAR:45.9},{AP:31,DMG:33.3,DMGFAR:46.1},{AP:32,DMG:33.3,DMGFAR:46.2},{AP:33,DMG:33.3,DMGFAR:46.3},{AP:34,DMG:33.3,DMGFAR:46.4},{AP:35,DMG:33.3,DMGFAR:46.5},{AP:36,DMG:33.3,DMGFAR:46.7},{AP:37,DMG:33.3,DMGFAR:46.8},{AP:38,DMG:33.3,DMGFAR:46.9},{AP:39,DMG:33.3,DMGFAR:47},{AP:41,DMG:33.3,DMGFAR:47.1},{AP:42,DMG:33.3,DMGFAR:47.2},{AP:44,DMG:33.3,DMGFAR:47.4},{AP:45,DMG:33.3,DMGFAR:47.5},{AP:47,DMG:33.3,DMGFAR:47.6},{AP:48,DMG:33.3,DMGFAR:47.6},{AP:51,DMG:33.3,DMGFAR:47.8},{AP:54,DMG:33.3,DMGFAR:47.9},{AP:57,DMG:33.3,DMGFAR:48}
  ],
  // デフォルトで1発撃つのに6.48フレームと思われる
  Nautilus : [
    {AP:0,frame:156},{AP:3,frame:168},{AP:6,frame:174},{AP:9,frame:178},{AP:10,frame:180},{AP:12,frame:182},{AP:13,frame:183},{AP:15,frame:185},{AP:16,frame:186},{AP:18,frame:188},{AP:19,frame:189},{AP:20,frame:190},{AP:21,frame:190},{AP:22,frame:191},{AP:23,frame:192},{AP:24,frame:192},{AP:25,frame:193},{AP:26,frame:194},{AP:27,frame:194},{AP:28,frame:195},{AP:29,frame:195},{AP:30,frame:196},{AP:31,frame:196},{AP:32,frame:197},{AP:33,frame:197},{AP:34,frame:198},{AP:35,frame:198},{AP:36,frame:199},{AP:37,frame:199},{AP:38,frame:199},{AP:39,frame:200},{AP:41,frame:200},{AP:42,frame:201},{AP:44,frame:201},{AP:45,frame:202},{AP:47,frame:202},{AP:48,frame:202},{AP:51,frame:203},{AP:54,frame:203},{AP:57,frame:203}
  ],
  Glooga : [
    {AP:0,DMG0:36,DMG1:52.5},{AP:3,DMG0:36.6,DMG1:53.5},{AP:6,DMG0:37.3,DMG1:54.4},{AP:9,DMG0:37.9,DMG1:55.3},{AP:10,DMG0:38.1,DMG1:55.6},{AP:12,DMG0:38.5,DMG1:56.2},{AP:13,DMG0:38.7,DMG1:56.5},{AP:15,DMG0:39.1,DMG1:57},{AP:16,DMG0:39.3,DMG1:57.3},{AP:18,DMG0:39.6,DMG1:57.8},{AP:19,DMG0:39.8,DMG1:58},{AP:20,DMG0:39.9,DMG1:58.2},{AP:21,DMG0:40.1,DMG1:58.5},{AP:22,DMG0:40.2,DMG1:58.7},{AP:23,DMG0:40.4,DMG1:58.9},{AP:24,DMG0:40.5,DMG1:59.1},{AP:25,DMG0:40.7,DMG1:59.3},{AP:26,DMG0:40.8,DMG1:59.5},{AP:27,DMG0:40.9,DMG1:59.7},{AP:28,DMG0:41.1,DMG1:59.9},{AP:29,DMG0:41.2,DMG1:60.1},{AP:30,DMG0:41.3,DMG1:60.3},{AP:31,DMG0:41.4,DMG1:60.5},{AP:32,DMG0:41.6,DMG1:60.6},{AP:33,DMG0:41.7,DMG1:60.8},{AP:34,DMG0:41.8,DMG1:61},{AP:35,DMG0:41.9,DMG1:61.1},{AP:36,DMG0:42,DMG1:61.2},{AP:37,DMG0:42.1,DMG1:61.4},{AP:38,DMG0:42.2,DMG1:61.5},{AP:39,DMG0:42.3,DMG1:61.7},{AP:41,DMG0:42.4,DMG1:61.9},{AP:42,DMG0:42.5,DMG1:62},{AP:44,DMG0:42.6,DMG1:62.2},{AP:45,DMG0:42.7,DMG1:62.3},{AP:47,DMG0:42.8,DMG1:62.5},{AP:48,DMG0:42.9,DMG1:62.6},{AP:51,DMG0:43,DMG1:62.7},{AP:54,DMG0:43.1,DMG1:62.9},{AP:57,DMG0:43.2,DMG1:63}
  ],
  Dapples : [
    {AP:0,DMG:36},{AP:3,DMG:36.6},{AP:6,DMG:37.3},{AP:9,DMG:37.9},{AP:10,DMG:38.1},{AP:12,DMG:38.5},{AP:13,DMG:38.7},{AP:15,DMG:39.1},{AP:16,DMG:39.3},{AP:18,DMG:39.6},{AP:19,DMG:39.8},{AP:20,DMG:39.9},{AP:21,DMG:40.1},{AP:22,DMG:40.2},{AP:23,DMG:40.4},{AP:24,DMG:40.5},{AP:25,DMG:40.7},{AP:26,DMG:40.8},{AP:27,DMG:40.9},{AP:28,DMG:41.1},{AP:29,DMG:41.2},{AP:30,DMG:41.3},{AP:31,DMG:41.4},{AP:32,DMG:41.6},{AP:33,DMG:41.7},{AP:34,DMG:41.8},{AP:35,DMG:41.9},{AP:36,DMG:42},{AP:37,DMG:42.1},{AP:38,DMG:42.2},{AP:39,DMG:42.3},{AP:41,DMG:42.4},{AP:42,DMG:42.5},{AP:44,DMG:42.6},{AP:45,DMG:42.7},{AP:47,DMG:42.8},{AP:48,DMG:42.9},{AP:51,DMG:43},{AP:54,DMG:43.1},{AP:57,DMG:43.2} 
  ],
  Dualies : [
    {AP:0,DMG:30},{AP:3,DMG:30.1},{AP:6,DMG:30.4},{AP:9,DMG:30.7},{AP:10,DMG:30.8},{AP:12,DMG:31.1},{AP:13,DMG:31.2},{AP:15,DMG:31.4},{AP:16,DMG:31.5},{AP:18,DMG:31.8},{AP:19,DMG:31.9},{AP:20,DMG:32},{AP:21,DMG:32.1},{AP:22,DMG:32.3},{AP:23,DMG:32.4},{AP:24,DMG:32.5},{AP:25,DMG:32.6},{AP:26,DMG:32.7},{AP:27,DMG:32.8},{AP:28,DMG:32.9},{AP:29,DMG:33},{AP:30,DMG:33.1},{AP:31,DMG:33.2},{AP:32,DMG:33.3},{AP:33,DMG:33.3},{AP:34,DMG:33.3},{AP:35,DMG:33.3},{AP:36,DMG:33.3},{AP:37,DMG:33.3},{AP:38,DMG:33.3},{AP:39,DMG:33.3},{AP:41,DMG:33.3},{AP:42,DMG:33.3},{AP:44,DMG:33.3},{AP:45,DMG:33.3},{AP:47,DMG:33.3},{AP:48,DMG:33.3},{AP:51,DMG:33.3},{AP:54,DMG:33.3},{AP:57,DMG:33.3}
  ],
  DualieSquelchers : [
    {AP:0,DMG:28},{AP:3,DMG:28.5},{AP:6,DMG:29},{AP:9,DMG:29.5},{AP:10,DMG:29.6},{AP:12,DMG:29.9},{AP:13,DMG:30.1},{AP:15,DMG:30.4},{AP:16,DMG:30.5},{AP:18,DMG:30.8},{AP:19,DMG:30.9},{AP:20,DMG:31},{AP:21,DMG:31.2},{AP:22,DMG:31.3},{AP:23,DMG:31.4},{AP:24,DMG:31.5},{AP:25,DMG:31.6},{AP:26,DMG:31.7},{AP:27,DMG:31.8},{AP:28,DMG:31.9},{AP:29,DMG:32},{AP:30,DMG:32.1},{AP:31,DMG:32.2},{AP:32,DMG:32.3},{AP:33,DMG:32.4},{AP:34,DMG:32.5},{AP:35,DMG:32.6},{AP:36,DMG:32.6},{AP:37,DMG:32.7},{AP:38,DMG:32.8},{AP:39,DMG:32.9},{AP:41,DMG:33},{AP:42,DMG:33},{AP:44,DMG:33.2},{AP:45,DMG:33.2},{AP:47,DMG:33.3},{AP:48,DMG:33.3},{AP:51,DMG:33.3},{AP:54,DMG:33.3},{AP:57,DMG:33.3}  
  ],
  Tetras : [
    {AP:0,DMG:28},{AP:3,DMG:28.5},{AP:6,DMG:29},{AP:9,DMG:29.5},{AP:10,DMG:29.6},{AP:12,DMG:29.9},{AP:13,DMG:30.1},{AP:15,DMG:30.4},{AP:16,DMG:30.5},{AP:18,DMG:30.8},{AP:19,DMG:30.9},{AP:20,DMG:31},{AP:21,DMG:31.2},{AP:22,DMG:31.3},{AP:23,DMG:31.4},{AP:24,DMG:31.5},{AP:25,DMG:31.6},{AP:26,DMG:31.7},{AP:27,DMG:31.8},{AP:28,DMG:31.9},{AP:29,DMG:32},{AP:30,DMG:32.1},{AP:31,DMG:32.2},{AP:32,DMG:32.3},{AP:33,DMG:32.4},{AP:34,DMG:32.5},{AP:35,DMG:32.6},{AP:36,DMG:32.6},{AP:37,DMG:32.7},{AP:38,DMG:32.8},{AP:39,DMG:32.9},{AP:41,DMG:33},{AP:42,DMG:33},{AP:44,DMG:33.2},{AP:45,DMG:33.2},{AP:47,DMG:33.3},{AP:48,DMG:33.3},{AP:51,DMG:33.3},{AP:54,DMG:33.3},{AP:57,DMG:33.3}
  ],
  Brella : [
    {AP:0,RT:390},{AP:3,RT:356},{AP:6,RT:336},{AP:9,RT:319},{AP:10,RT:313},{AP:12,RT:304},{AP:13,RT:299},{AP:15,RT:291},{AP:16,RT:287},{AP:18,RT:279},{AP:19,RT:276},{AP:20,RT:272},{AP:21,RT:269},{AP:22,RT:266},{AP:23,RT:263},{AP:24,RT:260},{AP:25,RT:257},{AP:26,RT:254},{AP:27,RT:252},{AP:28,RT:249},{AP:29,RT:247},{AP:30,RT:244},{AP:31,RT:242},{AP:32,RT:240},{AP:33,RT:238},{AP:34,RT:236},{AP:35,RT:234},{AP:36,RT:232},{AP:37,RT:230},{AP:38,RT:228},{AP:39,RT:227},{AP:41,RT:224},{AP:42,RT:222},{AP:44,RT:220},{AP:45,RT:218},{AP:47,RT:216},{AP:48,RT:215},{AP:51,RT:213},{AP:54,RT:211},{AP:57,RT:210}
  ],
  Tent : [
    {AP:0,HP:700},{AP:3,HP:728.9},{AP:6,HP:756.4},{AP:9,HP:782.5},{AP:10,HP:790.9},{AP:12,HP:807.1},{AP:13,HP:815},{AP:15,HP:830.2},{AP:16,HP:837.6},{AP:18,HP:851.9},{AP:19,HP:858.8},{AP:20,HP:865.6},{AP:21,HP:872.1},{AP:22,HP:878.5},{AP:23,HP:884.8},{AP:24,HP:890.9},{AP:25,HP:896.8},{AP:26,HP:902.6},{AP:27,HP:908.2},{AP:28,HP:913.6},{AP:29,HP:918.9},{AP:30,HP:924.1},{AP:31,HP:929},{AP:32,HP:933.8},{AP:33,HP:938.4},{AP:34,HP:942.9},{AP:35,HP:947.2},{AP:36,HP:951.4},{AP:37,HP:955.4},{AP:38,HP:959.2},{AP:39,HP:962.8},{AP:41,HP:969.7},{AP:42,HP:972.9},{AP:44,HP:978.7},{AP:45,HP:981.4},{AP:47,HP:986.3},{AP:48,HP:988.5},{AP:51,HP:994.2},{AP:54,HP:998.4},{AP:57,HP:1000}
  ],
  Undercover : [
    {AP:0,RT:270},{AP:3,RT:225},{AP:6,RT:210},{AP:9,RT:200},{AP:10,RT:197},{AP:12,RT:192},{AP:13,RT:190},{AP:15,RT:186},{AP:16,RT:184},{AP:18,RT:180},{AP:19,RT:178},{AP:20,RT:177},{AP:21,RT:175},{AP:22,RT:174},{AP:23,RT:172},{AP:24,RT:171},{AP:25,RT:170},{AP:26,RT:169},{AP:27,RT:167},{AP:28,RT:166},{AP:29,RT:165},{AP:30,RT:164},{AP:31,RT:163},{AP:32,RT:162},{AP:33,RT:161},{AP:34,RT:161},{AP:35,RT:160},{AP:36,RT:159},{AP:37,RT:158},{AP:38,RT:158},{AP:39,RT:157},{AP:41,RT:156},{AP:42,RT:155},{AP:44,RT:154},{AP:45,RT:154},{AP:47,RT:153},{AP:48,RT:152},{AP:51,RT:151},{AP:54,RT:151},{AP:57,RT:150}
  ],
}